import React from "react";
import { useTranslation } from "react-i18next";

const Parteneriate = () => {
  const partnershipsChina = [
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "Chinese People’s Association for Friendship with Foreign Countries (CPAFFC)",
      datePlace: "04.02.2015 / Beijing",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China National Corporation for Overseas Economic Cooperation (CNCOCE)",
      datePlace: "05.07.2018 / Beijing",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "Foreign Organizations (China) Office Center (FOCOC)",
      datePlace: "15.06.2021 / Bucharest",
    },
    {
      documentType: "Strategic Cooperation Agreement",
      cosigner: "China Economic Cooperation Center (CECC)",
      datePlace: "14.07.2017 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "China Foreign Trade Centre (CFTC)",
      datePlace: "04.05.2018 / Beijing",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Eu – China Business Association",
      datePlace: "15.04.2015 / Bucharest",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "China Certification and Inspection Group (Beijing)",
      datePlace: "11.06.2017 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Optics and Optoelectronics Manufacturers Association (COEMA)",
      datePlace: "05.12.2018 / Beijing",
    },
    {
      documentType: "Partnership Agreement",
      cosigner: "China GOABROAD LLC",
      datePlace: "08.09.2017 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "BOAO Forum for Asia",
      datePlace: "06.02.2017 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "China Import & Export Fair (Canton)",
      datePlace: "17.05.2018 / Guangzhou",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner:
        "Sail Of Shanghai “B&R“ Trade And Economic Exhibition Committee",
      datePlace: "15.07.2021 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "China Quanzhou Maritime Silk Road International Brand Expo",
      datePlace: "18.04.2019 / Quanzhou",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Dalian Import and Export Commodities Fair Committee",
      datePlace: "14.09.2018 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Ningbo Municipal Commission of Commerce",
      datePlace: "05.11.2018 / Ningbo",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "The CPC Yiwu Municipal Committee",
      datePlace: "15.03.2015 / Yiwu",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "The Department of Commerce of Jilin Province",
      datePlace: "27.04.2019 / Bucharest",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "Shenzhen CEEC Economy and Culture Association",
      datePlace: "27.09.2016 / Shenzhen",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "Shenzhen Association of Trade in Services",
      datePlace: "28.10.2019 / Shenzhen",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Zibo Economic Development Zone Management Committee",
      datePlace: "13.04.2016 / Zibo",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Winliner Global Information Technology (Wuhan)",
      datePlace: "09.08.2019 / Bucharest",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "SINOTRADE Services Corp. LLC",
      datePlace: "24.11.2021 / Bucharest",
    },
  ];

  const partnershipsChineseChambers = [
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Guangzhou",
      datePlace: "11.04.2015 / Guangzhou",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Shandong",
      datePlace: "01.06.2015 / Shandong",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Nanjing",
      datePlace: "02.06.2020 / Nanjing",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Hangzhou",
      datePlace: "13.01.2013 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Beijing Sub-Council",
      datePlace: "23.09.2019 / Beijing",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Ningbo Sub-Council",
      datePlace: "16.10.2015 / Ningbo",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Sichuan Sub-Council",
      datePlace: "02.06.2021 / Sichuan",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Hunan Sub-Council",
      datePlace: "11.04.2016 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Henan Sub-Council",
      datePlace: "08.09.2020 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Jiangxi Sub-Council",
      datePlace: "15.05.2018 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Liaoning Sub-Council",
      datePlace: "15.12.2015 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner:
        "China Council for the Promotion of International Trade (CCPIT) Shenyang Sub-Council",
      datePlace: "30.10.2019 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Silk Road Chamber of International Commerce",
      datePlace: "13.04.2017 / Beijing",
    },
    {
      documentType: "Memorandum of Understanding",
      cosigner: "China Chamber of International Commerce Shenzhen Branch",
      datePlace: "27.09.2016 / Shenzhen",
    },
    {
      documentType: "Strategic Agreement",
      cosigner: "China Chamber of International Commerce Jiangdong Branch",
      datePlace: "03.06.2015 / Bucharest",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "China Chamber of International Commerce Hangzhou Branch",
      datePlace: "02.03.2015 / Hangzhou",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Chongqing General Chamber of Commerce",
      datePlace: "02.02.2018 / Chongqing",
    },
    {
      documentType: "Cooperation Agreement",
      cosigner: "Intcham Hefei Center",
      datePlace: "13.03.2020 / Hefei",
    },
  ];

  const { t } = useTranslation();

  return (
    <section className="p-8 space-y-12 bg-white rounded-lg">
      {/* Titlu principal */}
      <h1 className="text-3xl font-bold text-center text-[var(--primary-color)]">
        {t("parteneriate")}
      </h1>

      {/* Secțiunea A - MOU și acorduri bilaterale cu entități românești */}
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          A. MOU and bilateral cooperation agreements with Romanian entities
        </h2>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 border border-gray-300 text-sm">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Document's Type
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Cosigner (Institution/Organization)
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Signing Date/Place
                </th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-300">
                  Cooperation Agreement
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  The Ministry of Economy, Entrepreneurship and Tourism
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  02.02.2019 / Bucharest
                </td>
              </tr>
              <tr className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-300">
                  Cooperation Agreement
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  State Office for Inventions and Trademarks (OSIM)
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  05.05.2016 / Bucharest
                </td>
              </tr>
              <tr className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-300">
                  Cooperation Agreement
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  Competition Council
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  03.04.2017 / Bucharest
                </td>
              </tr>
              <tr className="border-b border-gray-200 hover:bg-gray-100">
                <td className="px-4 py-2 border-b border-gray-300">
                  Cooperation Agreement
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  Chinese Visa Application Service Center (CVASC)
                </td>
                <td className="px-4 py-2 border-b border-gray-300">
                  17.05.2021 / Bucharest
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          B. MOU and bilateral cooperation agreements with Chinese Organizations
        </h2>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 border border-gray-300 text-sm">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Document's Type
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Cosigner (Institution/Organization)
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Signing Date/Place
                </th>
              </tr>
            </thead>
            <tbody>
              {partnershipsChina.map((partnership, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.documentType}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.cosigner}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.datePlace}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <div className="space-y-6">
        <h2 className="text-2xl font-semibold text-gray-800">
          C. Bilateral agreements with Chinese Chambers of Commerce
        </h2>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-50 border border-gray-300 text-sm">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Document's Type
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Cosigner (Institution/Organization)
                </th>
                <th className="px-4 py-2 border-b border-gray-300 text-left text-gray-800 font-bold">
                  Signing Date/Place
                </th>
              </tr>
            </thead>
            <tbody>
              {partnershipsChineseChambers.map((partnership, index) => (
                <tr
                  key={index}
                  className="border-b border-gray-200 hover:bg-gray-100"
                >
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.documentType}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.cosigner}
                  </td>
                  <td className="px-4 py-2 border-b border-gray-300">
                    {partnership.datePlace}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default Parteneriate;
