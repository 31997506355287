import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const PaginaAmbasador = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Ambasador Viorel Isticioaia-Budura</title>
        <meta
          name="description"
          content="Ambasador Viorel Isticioaia-Budura, președinte de onoare al CCIRC"
        />
        <meta
          name="keywords"
          content="ambasador, viorel isticioaia-budura, presedinte de onoare, ccirc, camera de comert si industrie, romania, china, ccircoffice, ccroch"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="min-h-screen bg-white rounded-lg text-gray-800">
        {/* Imaginea */}
        <div className="w-full">
          <img
            src="/isticioaia-prezentare.jpg"
            alt="Viorel Isticioaia-Budura"
            className="w-full h-auto rounded-t-lg object-cover"
          />
        </div>

        {/* Conținutul */}
        <div className="max-w-4xl mx-auto px-6 py-8">
          {/* Titlul */}
          <h1 className="text-3xl font-bold text-gray-900">
            {t("amb_viorel_isticioaia_budura")}
          </h1>
          <p className="text-lg text-blue-600 font-semibold">
            {t("presedinte_de_onoare_ccirc")}
          </p>

          {/* Descrierea */}
          <div className="mt-4 space-y-3 text-justify">
            <p>{t("ambasador_p1")}</p>
            <p>{t("ambasador_p2")}</p>
            <p>{t("ambasador_p3")}</p>
            <p>{t("ambasador_p4")}</p>
            <p>{t("ambasador_p5")}</p>
            <p>{t("ambasador_p6")}</p>
            <p className="font-semibold text-lg">
              Viorel Isticioaia-Budura,
              <br /> {t("ambasador_de_cariera")},
              <br /> {t("presedinte_de_onoare_ccirc")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaginaAmbasador;
