import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";
import { useTranslation } from "react-i18next";

export default function Hero() {
  const { setUserInfo, userInfo } = useContext(UserContext);
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/profile`, {
      credentials: "include",
    }).then((response) => {
      response.json().then((userInfo) => {
        setUserInfo(userInfo);
      });
    });
  }, []);

  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_API_URL}/logout`, {
      credentials: "include",
      method: "POST",
    });
    setUserInfo(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  const role = userInfo?.role;
  const username = userInfo?.username;
  const fullName = userInfo?.firstname + " " + userInfo?.lastname;
  return (
    <div className="relative w-full container mx-auto mt-4">
      <img
        src="/hero.webp"
        alt="Camera de Comert si Industrie Romania - China Discutii"
        className="w-full h-80 object-cover rounded-md"
      />

      <div className="absolute bg-white/15 p-2 rounded-md top-4 right-8 gap-1 flex flex-col items-center">
        <button
          className="text-white rounded-lg flex items-center"
          onClick={() => changeLanguage("ro")}
        >
          RO
          <img
            src="https://flagcdn.com/w20/ro.png"
            alt="RO Flag"
            className="ml-1"
          />
        </button>
        <hr className="w-4/5 border-white" />
        <button
          className="text-white rounded-lg flex items-center"
          onClick={() => changeLanguage("ch")}
        >
          CH
          <img
            src="https://flagcdn.com/w20/cn.png"
            alt="CH Flag"
            className="ml-1"
          />
        </button>
        <hr className="w-4/5 border-white" />
        <button
          className="text-white rounded-lg flex items-center"
          onClick={() => changeLanguage("en")}
        >
          EN
          <img
            src="https://flagcdn.com/w20/gb.png"
            alt="EN Flag"
            className="ml-1"
          />
        </button>
      </div>

      {userInfo?.firstname && (
        <div className="absolute bg-white/90 py-1 px-2 rounded-lg shadow-md hidden md:block bottom-4 left-8">
          <h1 className="text-lg font-semibold text-gray-800">
            {t("bine_ai_venit")}, {fullName}!
          </h1>
        </div>
      )}

      {role === "admin" && (
        <div className="absolute font-medium bottom-4 right-8 flex gap-3">
          <Link
            className="bg-[var(--primary-hover)] text-white py-1 px-2 text-sm rounded-lg shadow-lg hover:bg-[var(--primary-color)]"
            to="/admin-panel"
          >
            Admin Panel
          </Link>
          <button
            onClick={handleLogout}
            className="bg-[var(--primary-hover)] text-white py-1 px-2 text-sm rounded-lg shadow-lg hover:bg-[var(--primary-color)]"
          >
            {t("deconectare")}
          </button>
        </div>
      )}

      {role === "member" && (
        <button
          onClick={handleLogout}
          className="absolute font-medium bottom-4 text-sm right-8 bg-[var(--primary-hover)] text-white py-1 px-2 rounded-lg shadow-lg hover:bg-[var(--primary-color)]"
        >
          {t("deconectare")}
        </button>
      )}

      {!username && (
        <Link
          to="/login"
          className="absolute font-medium bottom-4 text-sm right-8 bg-[var(--primary-hover)] text-white py-1 px-2 rounded-lg shadow-lg hover:bg-[var(--primary-color)]"
        >
          {t("conectare_membri")}
        </Link>
      )}
    </div>
  );
}
