import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function PaginaInLucru() {
  const { t } = useTranslation();
  return (
    <div className="bg-white py-12 rounded-lg flex flex-col items-center justify-center text-center">
      <div className="mb-4">
        <svg
          className="animate-spin h-16 w-16 text-[var(--primary-color)] mx-auto"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8v8H4z"
          ></path>
        </svg>
      </div>
      <h1 className="text-2xl font-bold text-gray-800">
        {t("pagina_in_lucru")}
      </h1>
      <p class="text-gray-600 mt-2">{t("pagina_in_lucru_p1")}</p>
      <Link
        to="/"
        className="mt-6 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md hover:bg-[var(--primary-hover)] transition"
      >
        {t("inapoi_la_pagina")}
      </Link>
    </div>
  );
}
