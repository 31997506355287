import React from "react";
import { useTranslation } from "react-i18next";

export default function ContractAdeziune() {
  const { t } = useTranslation();
  return (
    <div className="bg-white rounded-lg pt-4 pb-12">
      <img src="/contract-adeziune-1.png" alt="Contract adeziune 1" />
      <img src="/contract-adeziune-2.png" alt="Contract adeziune 2" />
      <img src="/contract-adeziune-3.png" alt="Contract adeziune 3" />
      <a
        href="/contract_adeziune_membru_national.docx" // Link către fișierul din folderul public
        download // Atribut pentru descărcare
        className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mx-auto hover:bg-[var(--primary-hover)] transition"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
        {t("descarca_contract")}
      </a>
    </div>
  );
}
