import React from "react";
import { useTranslation } from "react-i18next";

export default function CatalogServiciiLogistica() {
  const { t } = useTranslation();
  return (
    <div className="bg-white text-gray-900 rounded-lg p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-gray-800">
        {t("catalog_servicii_de_logistica")}
      </h1>
      <h5 className="text-2xl text-[var(--primary-color)] font-bold">
        {t("capitolul_1")}.
      </h5>
      <p className="text-xl text-gray-800 mb-4">{t("logistica_1")}</p>
      <img src="/servicii1.jpg" alt="servicii1" className="w-full mb-10" />
      <h5 className="text-2xl text-[var(--primary-color)] font-bold">
        {t("capitolul_2")}.
      </h5>
      <p className="text-xl text-gray-800 mb-4">{t("logistica_2")}</p>
      <img src="/servicii2.jpg" alt="servicii1" className="w-full mb-4" />
      <a
        href="/catalog_servicii_logistica.pptx" // Link către fișierul din folderul public
        download // Atribut pentru descărcare
        className="px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mt-8 ml-auto hover:bg-[var(--primary-hover)] transition"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
          />
        </svg>
        {t("descarca_brosura")}
      </a>
    </div>
  );
}
