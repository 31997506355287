import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaHandshake, FaGavel, FaChevronDown } from "react-icons/fa";

const reportsData = [
  {
    title: "A.1. Identificare și Verificare Potențiali Parteneri",
    description:
      "Cercetare specializată care are ca obiectiv identificarea potențialilor parteneri de afaceri din România și R.P. Chineză pentru companiile romanești și chineze. Raportul cuprinde informații principale despre compania selectată (date de identificare; sediul social; filiale/sucursale; sedii secundare/puncte de lucru; domeniul principal de activitate; acționariat; administratori; management; capital social; starea firmei; cifra de afaceri; profit/pierdere; datorii; angajați; evoluția față de anii anteriori; date de contact).",
  },
  {
    title: "A.2. Identificare și Promovare Oportunități de Afaceri",
    description:
      "Cercetare specializată care are ca obiectiv identificarea potențialelor oportunități și proiecte de afaceri din România și R.P. Chineză (tip proiect; domeniu; dezvoltator; nivel și tip de investiție; business plan) și promovarea acestora către companiile interesate.",
  },
  {
    title: "A.3. Cercetare asupra Economiei și Mediului de Afaceri",
    description:
      "Cercetare specializată pentru elaborarea de studii documentare, statistici și evaluări cu privire la evoluția și caracteristicile social - economice și mediul de afaceri din România și R.P. Chineză (legislație în domeniul economic, financiar și al afacerilor; indicatori economici, financiari și comerciali; indicatori privind comerțul exterior; importatori și exportatori pe domenii de activitate; facilitățile statului pentru stimularea și atragerea investițiilor s.a.).",
  },
  {
    title: "A.4. Studii de Piață",
    description:
      "Cercetare specializată pentru elaborare de studii de piață cu privire la profilul potențialilor clienți din România și R.P.Chineză, evoluția produselor și serviciilor în aceste state, indicatori de producție și principali producători, indicatori deservicii și principali furnizori, analiza concurenței pe domenii de activitate s.a.",
  },
  {
    title: "A.5. Reprezentare, Negociere și Advocacy",
    description:
      "Facilitarea contactelor instituționale și diplomatice, precum și reprezentarea companiilor românești și chineze la întâlniri de afaceri, asistență pentru activitatea de relații publice și comunicare, asistență la negocierea și încheierea parteneriatelor și elaborarea documentelor bilaterale (contracte, acorduri, memorandumuri, etc).",
  },
  {
    title: "A.6. Organizare de Evenimente",
    description:
      "Organizare de evenimente pentru prezentarea companiilor românești și chineze și a brand-urilor acestora; organizare de întâlniri pentru companie și invitații săi, potențiali parteneri sau clienți; organizarea vizitelor delegațiilor companiei în România sau R.P Chineză; transmiterea de invitații pentru participarea la evenimentele companiei; închirieri săli de sedințe și asigurarea logisticii specifice; asistență pentru primire invitați; coordonarea evenimentelor și servicii de MC; evaluarea evenimentelor pe bază de chestionare; facilitarea accesului și participarea companiei la târguri, expoziții și alte asemenea evenimente economice și comerciale care au loc în România sau R.P. Chineză; organizarea de întâlniri de tip B2B.",
  },
  {
    title: "A.7. Informare",
    description:
      "Transmite news-letter, comunicări sau rapoarte ce furnizează companiei informații privind: evenimente de interes din România sau R.P. Chineză; mediul de afaceri, politicile de concurență și practicile de proprietate intelectuală din România sau R.P. Chineză; cadrul legislativ cu incidență asupra mediului de afaceri și investițional din România sau R.P. Chineză; identificarea și atragerea surselor de finanțare pentru proiectele de afaceri; formalitățile, normele și procedurile privind înființarea, organizarea și funcționarea societăților comerciale; oportunitățile de participare a reprezentanților companiei la diverse forme de training sau la accesarea de burse de studii în cele două țări.",
  },
  {
    title: "A.8. Înființare și Asistență de Management",
    description:
      "Acordarea de asistență pentru constituiri sau modificări societăți comerciale în România și R.P. Chineză; asistență privind problemele de guvernanță și conformitate; coduri de conduită; drepturiile acționarilor; asistență organizare adunări ale acționarilor / asociaților; asistență în disputele acționarilor și conducerii.",
  },
  {
    title: "A.9. Promovare",
    description:
      "Promovează serviciile sau produsele companiei la nivelul comunității de afaceri chineze din România și în cadrul întâlnirilor cu delegațiile companiilor chineze care prospectează prezența lor în țara noastră cu scopul derulării unor proiecte de investiții pe termen lung; promovează serviciile sau produsele companiei, inclusiv ofertele speciale ale acesteia către membrii camerei bilaterale, direct sau prin intermediului sistemului M2M; acordă spațiu pentru intervenții sau speech-uri ale reprezentanților companiei în programul evenimentelor CCIRC.",
  },
  {
    title: "A.10. Publicitate",
    description:
      "Planuri de publicitate a companiilor românești și chineze pe paginile social-media ale camerei bilaterale din Facebook, Twitter și Linkedin, precum și pe website-ul CCIRC (logo, stire, e-banner); amplasare de materiale publicitare (banner, roll-up, spider) în cadrul evenimentelor CCIRC, postarea logo-ului companiei pe documentele și materialele de prezentare ale CCIRC; promovarea produselor și serviciilor companiei în cadrul standurilor  organizate  de  camera  bilaterală  la  târgurile  și  expozițiile  la  care  este  prezentă,  în  România  sau în R.P. Chineză.",
  },
  {
    title: "A.11. Asistență Turistică pentru Afaceri",
    description:
      "Oferă companiilor românești și chineze servicii de turism și călătorie contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de persoane, rezervări servicii hoteliere, programe turistice și pentru turismul de afaceri, ghizi însoțitori, rent-a-car; acordă asistență pentru întocmirea documentelor specifice obținerii vizelor de tranzit, de scurtă sau lungă ședere în R.P. Chineză, pentru personalul sau reprezentanții companiei.",
  },
  {
    title: "A.12. Logistică",
    description:
      "Oferă companiilor românești și chineze servicii de logistică, contractate de camera bilaterală cu partenerii săi, la tarife preferențiale: transport intern și internațional de marfă ( terestru, naval și aerian), inspecție și control marfă, comisionari vamali, alte servicii specifice operațiunilor de import - export.",
  },
  {
    title: "A.13. Asistență la Angajarea Personalului Expat",
    description:
      "Asistență asupra procedurilor de obținere a vizelor în scop de muncă și de angajare a lucrătorilor proveniți din R.P. Chineză sau România, după caz, respectiv pentru personalul detașat; consultanță privind plata taxelor de asigurări sociale și alte obligații ale angajatorului față de lucrătorii expați.",
  },
  {
    title: "A.14. Training",
    description:
      "Organizare de evenimente de tip corporate și cursuri de legislație economică și comercială, comunicare și negociere în afaceri, cursuri de limba chineză și limba română pentru reprezentanții și personalul companiilor românești și chineze.",
  },
];

const consultancyData = [
  {
    categorie: "Rapoarte de Analiză Juridică a Companiei",
    listaRapoarte: [
      {
        title: "B.I.1. Informații Juridice Generale Despre Companie",
        description:
          "Cuprinde informații juridice principale despre companie (date de identificare; sediul social; filiale / sucursale; sedii secundare / puncte de lucru; domeniul principal de activitate; acționariat; capital social; starea firmei; administratori; management; date de contact).",
      },
      {
        title: "B.I.2. Informații Despre Acționarii Companiei",
        description:
          "Raport de structură a grupului, care prezintă situația fiecărui acționar din punct de vedere al participațiilor deținute în cadrul altor societăți (acțiuni / părți sociale deținute; valoare participație; procent capital social deținut).",
      },
      {
        title: "B.I.3. Informații Despre Mărcile Comerciale ale Companiei",
        description:
          "Cuprinde informații principale despre portofoliul de proprietate intelectuală deținut de companie (mărci comerciale; drepturi de autor; alte proprietăți intangibile).",
      },
      {
        title: "B.I.4. Informații Despre Ipotecile Imobiliare ale Companiei",
        description:
          "Cuprinde informații despre ipotecile imobiliare pe care o companie le are înscrise în favoarea creditorilor (arhiva AEGRM).",
      },
      {
        title: "B.I.5. Informații Despre Insolvența Companiei",
        description:
          "Prezintă motivația declanșării procedurii de insolvență, etapele parcurse, stadiul în care se află (Buletinul insolvenței).",
      },
      {
        title: "B.I.6. Informații Despre Procesele Companiei",
        description:
          "Oferă informații cu privire la procesele în care a fost implicată compania și dosarele aflate pe rolul instanțelor de judecată (litigii civile și comerciale; insolvențe; lichidări; dizolvări).",
      },
    ],
  },
  {
    categorie: "Rapoarte de Analiză Financiară a Companiei",
    listaRapoarte: [
      {
        title: "B.II.1. Informații Financiare Generale Despre Companie",
        description:
          "Cuprinde informații financiare principale despre companie (cifra de afaceri; profit/pierdere; datorii; active imobilizate; active circulante; capitaluri proprii; angajați; evoluția față de anii anteriori).",
      },
      {
        title: "B.II.2. Analiza Ratingului Financiar al Companiei",
        description:
          "Evidențiază stabilitatea financiară a companiei și capacitatea de achitare a obligațiilor financiare ale acesteia, analizând indicatorii de profitabilitate, solvabilitate, lichiditate și eficiență.",
      },
      {
        title: "B.II.3. Analiza Gradului de Risc al Companiei",
        description:
          "Cuprinde informații financiare și juridice relevante pentru a evidenția gradul de risc al companiei în raport cu decizia care trebuie luată cu privire la stabilirea și viitorul unei relații contractuale cu aceasta). Analiză situație curentă de risc (AS-IS) la nivel de organizație și propunerea de soluții de gestiune a riscului și planuri de continuitate a activității.",
      },
      {
        title: "B.II.4. Stabilirea Valorii de Piață a Companiei",
        description:
          "Calculul valorii de piață a companiei luând în considerare indicatorii financiari, evoluția afacerii, domeniul în care activează firma și concurența, activele, clienții și volumul vânzărilor etc).",
      },
      {
        title: "B.II.5. Evaluarea Probabilității Riscului de Insolvență",
        description:
          "Analiza indicatorilor financiari ai companiei care relevă probabilitatea ca aceasta să fie într-o situație de a nu-și achita datoriile față de creditori. Asigurarea portofoliului de clienți împotriva riscului de neplată datorată insolvenței, falimentului debitorului sau a neplății prelungite.",
      },
      {
        title: "B.II.6. Stabilirea Limitărilor pentru Plățile la Termen",
        description:
          "Evidențiază din analize sumele maxime pentru care compania poate plăti furnizorilor ei mărfuri sau servicii cu plata la termen.",
      },
      {
        title: "B.II.7. Datoriile Restante la Bugetul de Stat",
        description:
          "Cuprinde informații privind obligațiile financiare restante la bugetul de stat care pot atrage blocarea conturilor.",
      },
      {
        title: "B.II.8. Relevarea Incidentelor de Plăți ale Companiei",
        description:
          "Cuprinde informații cu privire la incidentele de plăți (CIP) aferente biletelor la ordin și cecurilor emise de persoana juridică în ultimii 7 ani.",
      },
    ],
  },
  {
    categorie: "Consultanță Fiscală",
    listaRapoarte: [
      {
        title: "B.III.1. Asistență și Optimizare Fiscală",
        description:
          "Analiza respectării legislației fiscale a operațiunilor; întocmirea, revizuirea și depunerea declarațiilor fiscale; asistență pe parcursul inspecțiilor fiscale; previziuni și planificare în materie fiscală; identificarea riscurilor și optimizarea fiscală; expertiză și opinii pentru gestionarea taxelor și impozitelor; consultanță privind plata TVA; asistență privind calculul impozitului pe profit.",
      },
      {
        title: "B.III.2. Due Diligence Fiscal",
        description:
          "Investigare, evaluare și analiză financiară; analize SWOT pentru achiziții de companii sau pentru accesarea unor oferte publice de vânzare / cumpărare companii sau imobile. Consultanță de risc în fuziuni și achiziții și furnizarea de soluții pentru tranzacții (warranty & indemnity insurance), asigurări listare pe bursă (IPO/SPO); due diligence de risc.",
      },
      {
        title: "B.III.3. Asistență Fiscală la Tranzacții",
        description:
          "Asistență la negocierea și stabilirea prețului tranzacției; strategii fiscale pentru fuziuni și achiziții; implementarea de structuri fiscale eficiente la momentul investiției, în faza operațională și la momentul unei vânzări ulterioare; consiliere cu privire la forma tranzacției etc.",
      },
      {
        title: "B.III.4. Asistență Financiar - Contabilă",
        description:
          "Contabilitate și salarizare; gestiune documente justificative; recenzii ale contabilității interne.",
      },
      {
        title: "B.III.5. Due Diligence Financiar",
        description:
          "Analiza situației financiare; studierea contractelor încheiate cu clienții, furnizorii și angajații; analiza veniturilor; proiecții financiare.",
      },
    ],
  },
  {
    categorie: "Consultanța de Afaceri",
    listaRapoarte: [
      {
        title: "B.IV.1. Restructurare în Situații de Criză",
        description:
          "Evaluarea strategică a afacerii; analiza necesarului de lichiditate; elaborarea planului de reorganizare; analiza factorilor de criză și a soluțiilor de contracarare și evitare; stabilirea pachetului de măsuri de redresare; surse noi de finanțare și negocieri bancare; plan de gestiune a crizei (crisis management).",
      },
      {
        title: "B.IV.2. Surse de Finanțare a Afacerii",
        description:
          "Informații complete cu privire la accesarea fondurilor nerambursabile naționale, europene sau internaționale; analiza eligibilității companiei; asistență pentru întocmirea proiectelor și a cererilor de finanțare; asistență pentru implementarea și monitorizarea proiectelor finanțate din astfel de surse.",
      },
      {
        title: "B.IV.3. Managementul Afacerii",
        description:
          "Asistență în derularea proiectelor de afaceri; managementul activităților de implementare a proiectelor de afaceri.",
      },
      {
        title: "B.IV.4. Achiziții Imobiliare",
        description:
          "Expertiză asupra proprietății țintă; redactarea documentelor aferente tranzacției; prezentare de oportunități pentru achiziții imobiliare; asistență la negocieri; due diligence pentru tranzacții și consultanță privind fondurile imobiliare; companii imobiliare; investitorii și dezvoltatori imobiliari.",
      },
      {
        title: "B.IV.5. Achiziții Publice",
        description:
          "Asistență pentru participarea la procedurile de achiziții publice; întocmirea documentației specifice participării la licitațiile pentru atribuirea proiectelor; asistență pentru constituirea de parteneriate pentru maximizarea șanselor de obținere a proiectelor etc. Asistență în obținerea de polițe de asigurare de garanții: de participare la licitație, bună execuție, retur avans, mentenanță; asigurări funcționale în conformitate cu prevederile caietelor de sarcini și a documentațiilor de atribuire întocmite de autoritățile contractante.",
      },
    ],
  },
  {
    categorie: "Consultanță Juridică",
    listaRapoarte: [
      {
        title: "B.V.1. Practici Concurențiale",
        description:
          "Consultanță în legislația concurenței; reprezentarea în fața autorităților naționale ale concurenței; asistență în structura tranzacțiilor și operațiunilor; asistență în vederea obținerii aprobării privind fuziunile și; asistență în situația investigațiilor autorității de concurență.",
      },
      {
        title: "B.V.2. Elaborare de Contracte Civile și Comerciale",
        description:
          "Elaborare și redactare proiecte contracte; verificare clauze contractuale; mediere în timpul procedurilor de stabilire a clauzei contractului.",
      },
      {
        title: "B.V.3. Litigii și Arbitraj",
        description:
          "Acordă companiei expertiză pentru analizarea, evaluarea și soluționarea disputelor și litigiilor comerciale administrative, fiscale și în materie de proprietate intelectuală cu parteneri din R.P. Chineză, înainte ca acestea să ajungă în instanță și oferă metode alternative de soluționare a acestora prin mediere, conciliere și arbitraj; asistă compania în fața Curții de Arbitraj Comercial Internațional de pe lângă Camera de Comerț și Industrie a României pentru soluționarea litigiilor comerciale, precum și în fața altor instituții europene sau internaționale.",
      },
      {
        title: "B.V.4. Proprietate Intelectuală",
        description:
          "Asistență pentru realizarea conformității legale privind mărcile comerciale, drepturile de autor și alte proprietăți intangibile; asistență în aspecte legate de protejarea portofoliului de proprietate intelectuală; asistență pentru înregistrarea mărcilor comerciale și a designului industrial la autoritățile naționale (OSIM și OAPI), europene sau internațional.",
      },
      {
        title: "B.V.5. Raporturile de Muncă",
        description:
          "Negocierea și implementarea de facilități pentru restructurarea angajaților; angajarea; redactarea documentelor de încetare a raporturilor de muncă; redactarea contractelor colective de muncă; asistență în rezolvarea litigiilor de muncă. Implementarea de programe deductibile fiscal de beneficii pentru angajați: asigurări private de sănătate (400 EUR/an/angajat - deductibil fiscal), pensii private (400 EUR/an/angajat - deductibil fiscal). Programe de beneficii flexibile pentru angajați.",
      },
    ],
  },
  {
    categorie: "Consultanță de Risc",
    listaRapoarte: [
      {
        title: "B.VI.1. Analiza Gradului de Risc al Companiei",
        description:
          "Cuprinde informații financiare și juridice relevante pentru a evidenția gradul de risc al companiei în raport cu decizia care trebuie luată cu privire la stabilirea și viitorul unei relații contractuale cu aceasta). Analiză situație curentă de risc (AS-IS) la nivel de organizație și propunerea de soluții de gestiune a riscului și planuri de continuitate a activității. Asigurarea portofoliului de clienți împotriva riscului de neplată datorată insolvenței, falimentului debitorului sau aneplății prelungite. Consultanță de risc în fuziuni și achiziții și furnizarea de soluții pentru tranzacții (warranty & indemnity insurance), asigurări listare pe bursă (IPO/SPO); due-dilligence de risc.",
      },
      {
        title: "B.VI.2. Managementul Riscurilor",
        description:
          "Asistență în stabilirea și îmbunătățirea sistemului de managementul riscului; analiza, evaluarea și prezentarea riscurilor; elaborarea ghidului de management al riscului; monitorizarea eficientă a riscurilor etc. Asistență în elaborarea planului de continuitate a activității (Business Continuity Plan); procedurilor și funcțiunilor de control al riscurilor la nivelul organizației (Enterprise Risk Management).",
      },
    ],
  },
];

const reportsDataInEnglish = [
  {
    title: "A.1. Identification and Verification of Potential Partners",
    description:
      "Specialized research aimed at identifying potential business partners from Romania and the People's Republic of China for Romanian and Chinese companies. The report includes main information about the selected company (identification data; registered office; branches / subsidiaries; secondary offices / workplaces; main field of activity; shareholding; administrators; management; share capital; company status; turnover; profit / loss; debts; employees; evolution compared to previous years; contact details).",
  },
  {
    title: "A.2. Identification and Promotion of Business Opportunities",
    description:
      "Specialized research aimed at identifying potential opportunities and business projects from Romania and the People's Republic of China (type of project; field; developer; level and type of investment; business plan) and promoting them to interested companies.",
  },
  {
    title: "A.3. Research on the Economy and Business Environment",
    description:
      "Specialized research for the elaboration of documentary studies, statistics and evaluations regarding the evolution and characteristics of the social - economic and business environment in Romania and the People's Republic of China (legislation in the economic, financial and business fields; economic, financial and commercial indicators; indicators regarding foreign trade; importers and exporters by field of activity; state facilities for stimulating and attracting investments etc.).",
  },
  {
    title: "A.4. Market Studies",
    description:
      "Specialized research for the elaboration of market studies regarding the profile of potential clients from Romania and the People's Republic of China, the evolution of products and services in these states, production indicators and main producers, service indicators and main suppliers, competition analysis by field of activity etc.",
  },
  {
    title: "A.5. Representation, Negotiation and Advocacy",
    description:
      "Facilitation of institutional and diplomatic contacts, as well as representation of Romanian and Chinese companies at business meetings, assistance for public relations and communication activities, assistance in negotiating and concluding partnerships and drafting bilateral documents (contracts, agreements, memoranda, etc.).",
  },
  {
    title: "A.6. Event Organization",
    description:
      "Organization of events for the presentation of Romanian and Chinese companies and their brands; organization of meetings for the company and its guests, potential partners or clients; organization of visits of company delegations to Romania or the People's Republic of China; sending invitations for participation in company events; renting meeting rooms and providing specific logistics; assistance for guest reception; event coordination and MC services; event evaluation based on questionnaires; facilitation of company access and participation in fairs, exhibitions and other such economic and commercial events taking place in Romania or the People's Republic of China; organization of B2B meetings.",
  },
  {
    title: "A.7. Information",
    description:
      "Sending news-letters, communications or reports that provide the company with information on: events of interest from Romania or the People's Republic of China; the business environment, competition policies and intellectual property practices in Romania or the People's Republic of China; the legal framework with implications on the business and investment environment in Romania or the People's Republic of China; identification and attraction of funding sources for business projects; formalities, rules and procedures for the establishment, organization and operation of commercial companies; opportunities for the company's representatives to participate in various training forms or access scholarships in the two countries.",
  },
  {
    title: "A.8. Establishment and Management Assistance",
    description:
      "Providing assistance for the establishment or modification of commercial companies in Romania and the People's Republic of China; assistance regarding governance and compliance issues; codes of conduct; shareholder rights; assistance in organizing shareholders' / associates' meetings; assistance in shareholder and management disputes.",
  },
  {
    title: "A.9. Promotion",
    description:
      "Promoting the services or products of the company within the Chinese business community in Romania and during meetings with Chinese company delegations that are prospecting their presence in our country for long-term investment projects; promoting the services or products of the company, including its special offers to the members of the bilateral chamber, directly or through the M2M system; providing space for interventions or speeches by the company's representatives in the CCIRC events program.",
  },
  {
    title: "A.10. Advertising",
    description:
      "Advertising plans for Romanian and Chinese companies on the social-media pages of the bilateral chamber on Facebook, Twitter and Linkedin, as well as on the CCIRC website (logo, news, e-banner); placement of advertising materials (banner, roll-up, spider) at CCIRC events, posting the company's logo on CCIRC presentation documents and materials; promoting the company's products and services at the stands organized by the bilateral chamber at fairs and exhibitions where it is present, in Romania or the People's Republic of China.",
  },
  {
    title: "A.11. Business Tourism Assistance",
    description:
      "Offering Romanian and Chinese companies tourism and travel services contracted by the bilateral chamber with its partners, at preferential rates: internal and international passenger transport, hotel service reservations, tourist programs and for business tourism, accompanying guides, rent-a-car; providing assistance for the preparation of the documents specific to obtaining transit visas, short or long stay visas in the People's Republic of China, for the company's personnel or representatives.",
  },
  {
    title: "A.12. Logistics",
    description:
      "Offering Romanian and Chinese companies logistics services, contracted by the bilateral chamber with its partners, at preferential rates: internal and international freight transport (land, sea and air), cargo inspection and control, customs brokers, other services specific to import - export operations.",
  },
  {
    title: "A.13. Assistance in Hiring Expat Staff",
    description:
      "Assistance with the procedures of obtaining work visas and hiring workers from the People's Republic of China or Romania, as the case may be, respectively for the detached personnel; consultancy regarding the payment of social security taxes and other obligations of the employer towards expatriate workers.",
  },
  {
    title: "A.14. Training",
    description:
      "Organization of corporate events and courses on economic and commercial legislation, communication and negotiation in business, courses in Chinese and Romanian for the representatives and staff of Romanian and Chinese companies.",
  },
];

const reportsDataInChinese = [
  {
    title: "A.1. 识别和验证潜在合作伙伴",
    description:
      "专门研究，旨在为罗马尼亚和中华人民共和国的罗马尼亚和中国公司识别潜在的商业合作伙伴。报告包括有关所选公司的主要信息（识别数据；注册办公室；分支机构/子公司；次要办公室/工作场所；主要活动领域；股权；管理人员；管理；股本；公司状态；营业额；利润/损失；债务；员工；与前几年的发展对比；联系方式）。",
  },
  {
    title: "A.2. 识别和推广商机",
    description:
      "专门研究，旨在识别罗马尼亚和中华人民共和国的潜在机会和商业项目（项目类型；领域；开发商；投资水平和类型；商业计划）并将其推广给感兴趣的公司。",
  },
  {
    title: "A.3. 经济和商业环境研究",
    description:
      "专门研究，用于制作关于罗马尼亚和中华人民共和国的社会经济和商业环境的发展和特征的文献研究，统计数据和评估（经济，金融和商业领域的立法；经济，金融和商业指标；外贸指标；按活动领域划分的进口商和出口商；国家为刺激和吸引投资而提供的设施等）。",
  },
  {
    title: "A.4. 市场研究",
    description:
      "专门研究，用于制作关于罗马尼亚和中华人民共和国潜在客户的概况，这两个国家产品和服务的发展，生产指标和主要生产商，服务指标和主要供应商，按活动领域划分的竞争分析等。",
  },
  {
    title: "A.5. 代表，谈判和倡导",
    description:
      "促进机构和外交联系，以及在商务会议上代表罗马尼亚和中国公司，协助公共关系和沟通活动，协助谈判和达成合作伙伴关系并起草双边文件（合同，协议，备忘录等）。",
  },
  {
    title: "A.6. 活动组织",
    description:
      "组织活动，以展示罗马尼亚和中国公司及其品牌；为公司及其客人，潜在合作伙伴或客户组织会议；组织公司代表团访问罗马尼亚或中华人民共和国；发送邀请参加公司活动；租用会议室并提供特定的后勤支 持；协助接待客人；活动协调和MC服务；根据问卷调查评估活动；促进公司参与和参与在罗马尼亚或中华人民共和国举行的展会，展览和其他经济和商业活动；组织B2B会议。",
  },
  {
    title: "A.7. 信息",
    description:
      "发送新闻通讯，通讯或报告，为公司提供有关以下信息：罗马尼亚或中华人民共和国感兴趣的事件；罗马尼亚或中华人民共和国的商业环境，竞争政策和知识产权实践；对罗马尼亚或中华人民共和国的商业和投资环境有影响的法律框架；为公司的代表提供参与各种培训形式或在两国获得奖学金的机会。",
  },
  {
    title: "A.8. 建立和管理协助",
    description:
      "为罗马尼亚和中华人民共和国的商业公司的建立或修改提供协助；有关治理和合规问题的协助；行为准则；股东权利；协助组织股东/合伙人会议；协助股东和管理层纠纷。",
  },
  {
    title: "A.9. 推广",
    description:
      "在罗马尼亚的中国商业界内推广公司的服务或产品，并在中国公司代表团的会议上推广他们在罗马尼亚长期投资项目的存在；推广公司的服务或产品，包括其特别 优惠给双边商会的成员, 直接或通过M2M系统;在CCIRC活动计划中提供公司代表干预或演讲的空间。",
  },
  {
    title: "A.10. 广告",
    description:
      "在双边商会在Facebook,Twitter和Linkedin上的社交媒体页面以及CCIRC网站(标志,新闻,电子横幅)上为罗马尼亚和中国公司制定广告计划;在CCIRC活动中放置广告材料(横幅,卷展,蜘蛛);在CCIRC展会的展台上张贴公司的标志;在罗马尼亚或中华人民共和国举办的展会上, 促进公司的产品和服务。",
  },
  {
    title: "A.11. 商务旅游协助",
    description:
      "为罗马尼亚和中国公司提供由双边商会与其合作伙伴签订的旅游和旅行服务，享有优惠价格：国内和国际客运，酒店服务预订，旅游计划和商务旅游，陪同导游，租车；为公司人员或代表准备获得中华人民共和国的过境签证，短期或长期停留签证的特定文件的协助。",
  },
  {
    title: "A.12. 物流",
    description:
      "为罗马尼亚和中国公司提供物流服务，由双边商会与其合作伙伴签订，享有优惠价格：国内和国际货运（陆地，海上和空中），货物检查和控制，报关代理，其他特定于进出口操作的服务。",
  },
  {
    title: "A.13. 协助雇佣外籍员工",
    description:
      "协助获得工作签证和雇佣来自中华人民共和国或罗马尼亚的工人的程序，分别为派遣人员；就雇主对外籍工人的社会保障税和其他义务的支付提供建议。",
  },
  {
    title: "A.14. 培训",
    description:
      "组织企业活动和课程，涉及经济和商业立法，商务沟通和谈判，罗马尼亚和中国公司的代表和员工的中文和罗马尼亚语课程。",
  },
];

const consultancyDataInEnglish = [
  {
    categorie: "Reports on the Company's Legal Status",
    listaRapoarte: [
      {
        title: "B.I.1. Legal Status Report",
        description:
          "Includes information on the company's legal status, the existence of legal disputes and the company's legal history.",
      },
      {
        title: "B.I.2. Legal Status Report for the Company's Shareholders",
        description:
          "Includes information on the legal status of the company's shareholders, the existence of legal disputes and the legal history of the shareholders.",
      },
      {
        title: "B.I.3. Legal Status Report for the Company's Administrators",
        description:
          "Includes information on the legal status of the company's administrators, the existence of legal disputes and the legal history of the administrators.",
      },
      {
        title: "B.I.4. Legal Status Report for the Company's Managers",
        description:
          "Includes information on the legal status of the company's managers, the existence of legal disputes and the legal history of the managers.",
      },
      {
        title: "B.I.5. Legal Status Report for the Company's Employees",
        description:
          "Includes information on the legal status of the company's employees, the existence of legal disputes and the legal history of the employees.",
      },
    ],
  },
  {
    categorie: "Reports on the Company's Financial Status",
    listaRapoarte: [
      {
        title: "B.II.1. Financial Status Report",
        description:
          "Includes information on the company's financial status, the existence of financial disputes and the company's financial history.",
      },
      {
        title: "B.II.2. Financial Status Report for the Company's Shareholders",
        description:
          "Includes information on the financial status of the company's shareholders, the existence of financial disputes and the financial history of the shareholders.",
      },
      {
        title: "B.II.3. Company Risk Analysis",
        description:
          "Includes relevant financial and legal information to highlight the company's risk level in relation to the decision to be made regarding the establishment and future of a contractual relationship with it). Analysis of the current risk situation (AS-IS at the organization level and proposal of risk management solutions and business continuity plans. Ensuring the client portfolio against the risk of non-payment due to insolvency, debtor bankruptcy or prolonged non-payment. Risk consultancy in mergers and acquisitions and provision of solutions for transactions (warranty & indemnity insurance), stock exchange listing insurance (IPO/SPO); risk due diligence.",
      },
      {
        title: "B.II.4. Financial Status Report for the Company's Managers",
        description:
          "Includes information on the financial status of the company's managers, the existence of financial disputes and the financial history of the managers.",
      },
      {
        title: "B.II.5. Assessment of the Probability of Insolvency Risk",
        description:
          "Analysis of the company's financial indicators that reveal the probability that it is in a situation of not paying its debts to creditors. Ensuring the client portfolio against the risk of non-payment due to insolvency, debtor bankruptcy or prolonged non-payment.",
      },
      {
        title: "B.II.6. Setting Limits for Payments on Time",
        description:
          "Highlights from the analysis the maximum amounts the company can pay to its suppliers for goods or services with payment on time.",
      },
      {
        title: "B.II.7. Outstanding Debts to the State Budget",
        description:
          "Includes information on outstanding financial obligations to the state budget that may lead to the blocking of accounts.",
      },
      {
        title: "B.II.8. Identification of Company Payment Incidents",
        description:
          "Includes information on payment incidents (CIP) related to promissory notes and checks issued by the legal entity in the last 7 years.",
      },
    ],
  },
  {
    categorie: "Tax Consultancy",
    listaRapoarte: [
      {
        title: "B.III.1. Tax Assistance and Optimization",
        description:
          "Analysis of the compliance with the tax legislation of the operations; preparation, review and submission of tax returns; assistance during tax inspections; forecasts and tax planning; identification of risks and tax optimization; expertise and opinions for managing taxes and duties; consultancy regarding VAT payment; assistance regarding the calculation of corporate income tax.",
      },
      {
        title: "B.III.2. Tax Due Diligence",
        description:
          "Investigation, evaluation and financial analysis; SWOT analyzes for acquisitions of companies or for accessing public offers for sale / purchase of companies or real estate. Risk consultancy in mergers and acquisitions and provision of solutions for transactions (warranty & indemnity insurance), stock exchange listing insurance (IPO/SPO); risk due diligence.",
      },
      {
        title: "B.III.3. Tax Assistance in Transactions",
        description:
          "Assistance in negotiating and setting the transaction price; tax strategies for mergers and acquisitions; implementation of efficient tax structures at the time of investment, in the operational phase and at the time of a subsequent sale; advice on the form of the transaction etc.",
      },
      {
        title: "B.III.4. Financial - Accounting Assistance",
        description:
          "Accounting and payroll; management of supporting documents; reviews of internal accounting.",
      },
      {
        title: "B.III.5. Financial Due Diligence",
        description:
          "Analysis of the financial situation; study of contracts concluded with customers, suppliers and employees; analysis of revenues; financial projections.",
      },
    ],
  },
  {
    categorie: "Business Consultancy",
    listaRapoarte: [
      {
        title: "B.IV.1. Restructuring in Crisis Situations",
        description:
          "Strategic evaluation of the business; analysis of the liquidity needs; preparation of the reorganization plan; analysis of crisis factors and counteraction and avoidance solutions; establishment of the recovery measures package; new sources of funding and bank negotiations; crisis management plan.",
      },
      {
        title: "B.IV.2. Business Funding Sources",
        description:
          "Complete information on accessing national, European or international non-repayable funds; analysis of the company's eligibility; assistance in preparing projects and funding applications; assistance in implementing and monitoring projects funded from such sources.",
      },
      {
        title: "B.IV.3. Business Management",
        description:
          "Assistance in carrying out business projects; management of business project implementation activities.",
      },
      {
        title: "B.IV.4. Real Estate Acquisitions",
        description:
          "Expertise on the target property; drafting the documents related to the transaction; presentation of opportunities for real estate acquisitions; assistance in negotiations; due diligence for transactions and consultancy on real estate funds; real estate companies; real estate investors and developers.",
      },
      {
        title: "B.IV.5. Public Procurement",
        description:
          "Assistance in participating in public procurement procedures; preparation of the specific documentation for participating in tenders for project awards; assistance in forming partnerships to maximize the chances of obtaining projects etc. Assistance in obtaining insurance policies: participation in the tender, good execution, advance return, maintenance; functional insurance in accordance with the specifications and award documentation prepared by the contracting authorities.",
      },
    ],
  },

  {
    categorie: "Legal Consultancy",
    listaRapoarte: [
      {
        title: "B.V.1. Competition Practices",
        description:
          "Consultancy in competition legislation; representation in front of national competition authorities; assistance in structuring transactions and operations; assistance in obtaining approval for mergers and; assistance in the event of competition authority investigations.",
      },
      {
        title: "B.V.2. Drafting Civil and Commercial Contracts",
        description:
          "Drafting and preparing contract drafts; verification of contractual clauses; mediation during contract clause determination procedures.",
      },
      {
        title: "B.V.3. Litigation and Arbitration",
        description:
          "Provides the company with expertise for analyzing, evaluating and resolving commercial, administrative, tax and intellectual property disputes with partners from the People's Republic of China, before they reach court and provides alternative methods of resolving them through mediation, conciliation and arbitration; assists the company in front of the International Commercial Arbitration Court of the Chamber of Commerce and Industry of Romania for the settlement of commercial disputes, as well as in front of other European or international institutions.",
      },
      {
        title: "B.V.4. Intellectual Property",
        description:
          "Assistance in achieving legal compliance regarding trademarks, copyrights and other intangible properties; assistance in aspects related to protecting the intellectual property portfolio; assistance in registering trademarks and industrial design with national (OSIM and OAPI), European or international authorities.",
      },
      {
        title: "B.V.5. Labor Relations",
        description:
          "Negotiation and implementation of facilities for restructuring employees; hiring; drafting documents for the termination of employment relationships; drafting collective labor contracts; assistance in resolving labor disputes. Implementation of tax deductible benefit programs for employees: private health insurance (400 EUR/year/employee - tax deductible), private pensions (400 EUR/year/employee - tax deductible). Flexible benefits programs for employees.",
      },
    ],
  },
  {
    categorie: "Risk Consultancy",
    listaRapoarte: [
      {
        title: "B.VI.1. Company Risk Analysis",
        description:
          "Includes relevant financial and legal information to highlight the company's risk level in relation to the decision to be made regarding the establishment and future of a contractual relationship with it). Analysis of the current risk situation (AS-IS at the organization level and proposal of risk management solutions and business continuity plans. Ensuring the client portfolio against the risk of non-payment due to insolvency, debtor bankruptcy or prolonged non-payment. Risk consultancy in mergers and acquisitions and provision of solutions for transactions (warranty & indemnity insurance), stock exchange listing insurance (IPO/SPO); risk due diligence.",
      },
      {
        title: "B.VI.2. Risk Management",
        description:
          "Assistance in establishing and improving the risk management system; analysis, evaluation and presentation of risks; elaboration of the risk management guide; efficient risk monitoring etc. Assistance in elaborating the business continuity plan; risk control procedures and functions at the organization level (Enterprise Risk Management).",
      },
    ],
  },
];

const consultancyDataInChinese = [
  {
    categorie: "公司法律状况报告",
    listaRapoarte: [
      {
        title: "B.I.1. 法律状况报告",
        description:
          "包括有关公司法律状况，法律纠纷的存在和公司法律历史的信息。",
      },
      {
        title: "B.I.2. 公司股东法律状况报告",
        description:
          "包括有关公司股东法律状况，法律纠纷的存在和股东的法律历史的信息。",
      },
      {
        title: "B.I.3. 公司管理者法律状况报告",
        description:
          "包括有关公司管理者法律状况，法律纠纷的存在和管理者的法律历史的信息。",
      },
      {
        title: "B.I.4. 公司经理法律状况报告",
        description:
          "包括有关公司经理法律状况，法律纠纷的存在和经理的法律历史的信息。",
      },
      {
        title: "B.I.5. 公司员工法律状况报告",
        description:
          "包括有关公司员工法律状况，法律纠纷的存在和员工的法律历史的信息。",
      },
    ],
  },
  {
    categorie: "公司财务状况报告",
    listaRapoarte: [
      {
        title: "B.II.1. 财务状况报告",
        description:
          "包括有关公司财务状况，财务纠纷的存在和公司财务历史的信息。",
      },
      {
        title: "B.II.2. 公司股东财务状况报告",
        description:
          "包括有关公司股东财务状况，财务纠纷的存在和股东的财务历史的信息。",
      },
      {
        title: "B.II.3. 公司风险分析",
        description:
          "包括相关的财务和法律信息，以突出公司的风险水平，以便就是否与其建立合同关系的决定进行评估。分析当前风险情况（组织层面的AS-IS和风险管理解决方案和业务连续性计划的建议。确保客户组合免受由于破产，债务人破产或长期不支付而导致的不支付风险。并提供解决交易的风险（担保和赔偿保险），上市保险（IPO/SPO）；风险尽职调查。",
      },
      {
        title: "B.II.4. 公司经理财务状况报告",
        description:
          "包括有关公司经理财务状况，财务纠纷的存在和经理的财务历史的信息。",
      },
      {
        title: "B.II.5. 破产风险概率评估",
        description:
          "分析公司的财务指标，揭示其不支付债务给债权人的可能性。确保客户组合免受由于破产，债务人破产或长期不支付而导致的不支付风险。",
      },
      {
        title: "B.II.6. 设置及时付款限额",
        description:
          "从分析中突出显示公司可以向供应商支付的最高金额，以便按时支付货物或服务。",
      },
      {
        title: "B.II.7. 欠缴国家预算款项",
        description: "包括有关欠缴国家预算款项的信息，这可能导致账户被冻结。",
      },
      {
        title: "B.II.8. 公司付款事故识别",
        description:
          "包括有关过去7年内法人发行的本票和支票的付款事故（CIP）的信息。",
      },
    ],
  },
  {
    categorie: "税务咨询",
    listaRapoarte: [
      {
        title: "B.III.1. 税务协助和优化",
        description:
          "分析操作是否符合税法;准备，审查和提交税务申报表;税务检查期间的协助;预测和税收规划;风险识别和税收优化;管理税收和税收义务的专业知识和意见;有关增值税支付的咨询;协助计算企业所得税。",
      },
      {
        title: "B.III.2. 税务尽职调查",
        description:
          "调查，评估和财务分析;用于收购公司或访问公共出售/购买公司或房地产的项目的SWOT分析。在并购中的风险咨询，并为交易提供解决方案（担保和赔偿保险），上市保险（IPO/SPO）；风险尽职调查。",
      },
      {
        title: "B.III.3. 交易中的税务协助",
        description:
          "协助谈判和确定交易价格;并购的税收策略;在投资时实施高效的税收结构，在运营阶段和在随后的出售时;关于交易形式的建议等。",
      },
      {
        title: "B.III.4. 财务 - 会计协助",
        description: "会计和工资;支持文件的管理;内部会计审查。",
      },
      {
        title: "B.III.5. 财务尽职调查",
        description:
          "财务状况分析;与客户，供应商和员工签订的合同的研究;收入分析;财务预测。",
      },
    ],
  },
  {
    categorie: "商业咨询",
    listaRapoarte: [
      {
        title: "B.IV.1. 危机情况下的重组",
        description:
          "业务的战略评估;流动性需求分析;制定重组计划;危机因素的分析和对策和避免解决方案;建立恢复措施包;新的融资来源和银行谈判;危机管理计划。",
      },
      {
        title: "B.IV.2. 商业资金来源",
        description:
          "有关访问国家，欧洲或国际不可退还资金的完整信息;公司资格的分析;协助准备项目和资金申请;协助实施和监督从这些来源资助的项目。",
      },
      {
        title: "B.IV.3. 商业管理",
        description: "协助进行商业项目;管理商业项目实施活动。",
      },
      {
        title: "B.IV.4. 房地产收购",
        description:
          "目标物业的专业知识;起草与交易相关的文件;房地产收购机会的介绍;协助谈判;交易的尽职调查和房地产基金的咨询;房地产公司;房地产投资者和开发商。",
      },
      {
        title: "B.IV.5. 公共采购",
        description:
          "协助参与公共采购程序;准备参与项目奖励招标的具体文件;协助形成合作伙伴关系，以最大化获得项目的机会等。协助获得保险政策：参与投标，良好执行，预付款退还，维护; 根据承包机构准备的规格和授予文件提供功能性保险。",
      },
    ],
  },

  {
    categorie: "法律咨询",
    listaRapoarte: [
      {
        title: "B.V.1. 竞争实践",
        description:
          "竞争立法的咨询;在国家竞争机构面前的代表;协助结构化交易和操作;协助并购获得批准;在竞争当局调查时提供协助。",
      },
      {
        title: "B.V.2. 起草民事和商业合同",
        description:
          "起草和准备合同草案;核对合同条款;在合同条款确定程序中进行调解。",
      },
      {
        title: "B.V.3. 诉讼和仲裁",
        description:
          "为公司提供专业知识，以分析，评估和解决商业，行政，税收和知识产权与中华人民共和国合作伙伴的纠纷，然后提供解决它们的替代方法，通过调解，调解和仲裁;协助公司在罗马尼亚商会国际商事仲裁法庭解决商业纠纷，以及在其他欧洲或国际机构面前提供协助。",
      },
      {
        title: "B.V.4. 知识产权",
        description:
          "协助实现商标，版权和其他无形财产的法律合规性;协助保护知识产权组合的相关方面;协助在国家（OSIM和OAPI），欧洲或国际机构注册商标和工业设计。",
      },
      {
        title: "B.V.5. 劳动关系",
        description:
          "为重组员工谈判和实施设施;雇佣;起草终止劳动关系文件;起草集体劳动合同;协助解决劳动纠纷。为员工实施可税扣除的福利计划：私人健康保险（每年400欧元/员工-可税扣除），私人养老金（每年400欧元/员工-可税扣除）。员工的灵活福利计划。",
      },
    ],
  },
  {
    categorie: "风险咨询",
    listaRapoarte: [
      {
        title: "B.VI.1. 公司风险分析",
        description:
          "包括相关的财务和法律信息，以突出公司的风险水平，以便就是否与其建立合同关系的决定进行评估。分析当前风险情况（组织层面的AS-IS和风险管理解决方案和业务连续性计划的建议。确保客户组合免受由于破产，债务人破产或长期不支付而导致的不支付风险。并提供解决交易的风险（担保和赔偿保险），上市保险（IPO/SPO）；风险尽职调查。",
      },
      {
        title: "B.VI.2. 风险管理",
        description:
          "协助建立和改进风险管理系统;风险的分析，评估和呈现;制定风险管理指南;有效的风险监控等。协助制定业务连续性计划;组织层面的风险控制程序和功能（企业风险管理）。",
      },
    ],
  },
];

const CatalogServiciiAfaceri = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const { t, i18n } = useTranslation();
  return (
    <div className="bg-white text-gray-900 rounded-lg p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-gray-800">
        {t("catalog_servicii_de_afaceri")}
      </h1>
      <div
        className={`border border-gray-200 rounded-lg shadow-md mb-4 transition-all duration-300 ${
          isOpen ? "shadow-lg" : "shadow-md"
        }`}
      >
        <div
          className="collapse-title text-xl font-semibold text-gray-800 flex items-center cursor-pointer p-4"
          onClick={() => setIsOpen(!isOpen)}
        >
          <FaHandshake className="text-[var(--primary-color)] text-2xl mr-4" />
          {t("consultanta_in_afaceri")}
          <FaChevronDown
            className={`ml-auto text-gray-500 text-xl transform transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </div>
        <div
          className={`overflow-hidden transition-[max-height] duration-300 ${
            isOpen ? "max-h-[3000px]" : "max-h-0"
          }`}
        >
          <div className="p-4 space-y-4 text-gray-700 bg-gray-50">
            {i18n.language === "en" && (
              <>
                {reportsDataInEnglish.map((item, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ))}
              </>
            )}
            {i18n.language === "ro" && (
              <>
                {reportsData.map((item, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ))}
              </>
            )}
            {i18n.language === "ch" && (
              <>
                {reportsDataInChinese.map((item, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg">{item.title}</h3>
                    <p>{item.description}</p>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>

      <div
        className={`border border-gray-200 rounded-lg shadow-md mb-4 transition-all duration-300 ${
          isOpen2 ? "shadow-lg" : "shadow-md"
        }`}
      >
        <div
          className="collapse-title text-xl font-semibold text-gray-800 flex items-center cursor-pointer p-4"
          onClick={() => setIsOpen2(!isOpen2)}
        >
          <FaGavel className="text-[var(--primary-color)] text-2xl mr-4" />
          {t("consultanta_comerciala")}
          <FaChevronDown
            className={`ml-auto text-gray-500 text-xl transform transition-transform ${
              isOpen2 ? "rotate-180" : ""
            }`}
          />
        </div>
        <div
          className={`overflow-hidden transition-[max-height] duration-300 ${
            isOpen2 ? "max-h-[6000px]" : "max-h-0"
          }`}
        >
          <div className="p-4 space-y-4 text-gray-700 bg-gray-50">
            {i18n.language === "ro" && (
              <>
                {consultancyData.map((category, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg mb-2">
                      {category.categorie}
                    </h3>
                    {category.listaRapoarte.map((raport, idx) => (
                      <div
                        key={idx}
                        className="bg-gray-200 p-4 mt-4 rounded-lg shadow-sm"
                      >
                        <h4 className="font-bold text-md">{raport.title}</h4>
                        <p>{raport.description}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
            {i18n.language === "en" && (
              <>
                {consultancyDataInEnglish.map((category, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg mb-2">
                      {category.categorie}
                    </h3>
                    {category.listaRapoarte.map((raport, idx) => (
                      <div
                        key={idx}
                        className="bg-gray-200 p-4 mt-4 rounded-lg shadow-sm"
                      >
                        <h4 className="font-bold text-md">{raport.title}</h4>
                        <p>{raport.description}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
            {i18n.language === "ch" && (
              <>
                {consultancyDataInChinese.map((category, index) => (
                  <div key={index} className="bg-gray-100 p-4 rounded-lg">
                    <h3 className="font-bold text-lg mb-2">
                      {category.categorie}
                    </h3>
                    {category.listaRapoarte.map((raport, idx) => (
                      <div
                        key={idx}
                        className="bg-gray-200 p-4 mt-4 rounded-lg shadow-sm"
                      >
                        <h4 className="font-bold text-md">{raport.title}</h4>
                        <p>{raport.description}</p>
                      </div>
                    ))}
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogServiciiAfaceri;
