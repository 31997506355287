import React from "react";

export default function SchimbValutarSkeleton() {
  return (
    <div className="max-w-md mx-auto shadow-lg rounded-lg my-3 overflow-hidden animate-pulse">
      {/* Header Skeleton */}
      <div className="bg-[#d9edf7] py-1">
        <div className="h-8 bg-gray-300 rounded w-1/3 mx-auto"></div>
      </div>

      {/* Content Skeleton */}
      <div className="pt-2 pb-3 bg-white">
        {["white", "gray-100", "white", "gray-100"].map((bgColor, index) => (
          <div
            key={index}
            className={`flex justify-between bg-${bgColor} px-2 py-1`}
          >
            <div className="flex items-center gap-1">
              <div className="w-8 h-4 bg-gray-300 rounded"></div>
              <div className="w-12 h-3 bg-gray-300 rounded"></div>
            </div>
            <div className="w-16 h-4 bg-gray-300 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
}
