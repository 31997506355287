import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

export default function SustineCCIRC() {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Sustine CCIRC</title>
        <meta
          name="description"
          content="Susține Camera de Comerț și Industrie România - China"
        />
        <meta
          name="keywords"
          content="sustine, camera de comert si industrie, romania, china, ccir, ccircoffice, ccroch"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>

      <section className="bg-white rounded-lg shadow-lg pt-6 px-6 pb-12">
        <h5 className="text-lg text-[var(--primary-color)] text-center font-bold mb-4">
          {t("sustine_ccirc")}
        </h5>
        <h3 className="text-2xl font-semibold text-center mb-6">
          {t("sc_p1")}
        </h3>
        <p className="mb-3">
          <span className="font-semibold">CCIRC</span> {t("sc_p2")}
        </p>
        <p className="mb-3">
          <span className="font-semibold">CCIRC</span> {t("sc_p3")}
        </p>
        <p className="mb-3">{t("sc_p4")}</p>
        <p>{t("sc_p5")}</p>
        <h5 className="text-xl mt-8 font-semibold text-[var(--primary-color)] mb-4">
          {t("sc_p6")}
        </h5>

        <p className="font-bold mb-1">{t("sc_p7")}</p>
        <p>{t("sc_p8")}</p>
        <p className="font-bold mb-1 mt-4">{t("sc_p9")}</p>
        <p>{t("sc_p10")}</p>
        <button className="mt-4 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mr-auto hover:bg-[var(--primary-hover)] transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          {t("sc_p11")}
        </button>
        <h5 className="text-xl mt-8 font-semibold text-[var(--primary-color)] mb-4">
          {t("sc_p12")}
        </h5>

        <p className="font-bold mb-1">{t("sc_p13")} </p>
        <p>{t("sc_p14")}</p>
        <p className="font-bold mb-1 mt-4">{t("sc_p15")}</p>
        <p>{t("sc_p16")}</p>
        <button className="mt-4 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mr-auto hover:bg-[var(--primary-hover)] transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          {t("sc_p17")}
        </button>
        <h5 className="text-xl mt-8 font-semibold text-[var(--primary-color)] mb-4">
          {t("sc_p18")}
        </h5>
        <p className="font-bold mb-1">{t("sc_p19")}</p>
        <p>{t("sc_p20")}</p>
        <p className="font-bold mb-1 mt-4">{t("sc_p21")}</p>
        <p>{t("sc_p22")}</p>
        <p className="font-bold mb-1 mt-4">{t("sc_p23")}</p>
        <p>{t("sc_p24")}</p>
        <button className="mt-4 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mr-auto hover:bg-[var(--primary-hover)] transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          {t("sc_p25")}
        </button>

        <h5 className="text-xl mt-8 font-semibold text-[var(--primary-color)] mb-4">
          {t("sc_p26")}
        </h5>
        <p className="font-bold mb-1">{t("sc_p27")}</p>
        <p>{t("sc_p28")}</p>
        <p className="font-bold mb-1 mt-4">{t("sc_p29")}</p>
        <p>{t("sc_p30")}</p>
        <a
          href="/230_OPANAF_15_2021.pdf" // Link către fișierul din folderul public
          download // Atribut pentru descărcare
          className="mt-4 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mr-auto hover:bg-[var(--primary-hover)] transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          {t("sc_p31")}
        </a>
        <p className="mt-6">{t("sc_p32")}</p>
        <a
          href="/D_212_2024.pdf" // Link către fișierul din folderul public
          download // Atribut pentru descărcare
          className="mt-4 px-4 py-2 bg-[var(--primary-color)] text-white rounded-lg shadow-md flex w-fit gap-2 mr-auto hover:bg-[var(--primary-hover)] transition"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
          {t("sc_p33")}
        </a>

        {/* Partea de jos */}
        <div className="text-center mt-12 mb-6">
          <h2 className="text-4xl font-extrabold text-[var(--primary-hover)]">
            {t("sc_p34")}
          </h2>
          <p className="text-gray-700 text-lg mt-2">{t("sc_p35")}</p>
        </div>

        {/* Card container */}
        <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
          {/* Decorative Header */}
          <div className="bg-[var(--primary-hover)] text-white text-center py-4">
            <h3 className="text-xl font-semibold uppercase">
              {t("camera_de_comert_si_industrie_romania_china")}
            </h3>
          </div>

          {/* Content */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 p-8">
            {/* Sediu și punct de lucru */}
            <div>
              <h4 className="text-lg font-semibold text-[var(--primary-hover)]">
                {t("sediu")}
              </h4>
              <p className="text-gray-700">
                B-dul Octavian Goga nr. 2, tronson 2, etaj 6, cam. 6, Sector 3,
                București
              </p>
              <h4 className="mt-6 text-lg font-semibold text-[var(--primary-hover)]">
                {t("punct_de_lucru")}
              </h4>
              <p className="text-gray-700">
                Bd. Unirii nr. 10, bl. 7B, sc. 2, etaj 6, ap. 45, sector 4,
                București
              </p>
            </div>

            {/* Contact */}
            <div>
              <h4 className="text-lg font-semibold text-[var(--primary-hover)]">
                {t("contact")}
              </h4>
              <p className="text-gray-700">
                <strong>{t("telefon")}:</strong> 0728 886 112
              </p>
              <p className="text-gray-700">
                <strong>{t("email")}:</strong> ccircoffice@gmail.com
              </p>
              <h4 className="mt-6 text-lg font-semibold text-[var(--primary-hover)]">
                {t("reprezentant_legal")}
              </h4>
              <p className="text-gray-700">Nicolae VASILESCU</p>
            </div>

            {/* Legal Information */}
            <div>
              <h4 className="text-lg font-semibold text-[var(--primary-hover)]">
                {t("informatii_legale")}
              </h4>
              <p className="text-gray-700">
                <strong>{t("nr_inreg")}:</strong> {t("registrul_asociatilor")}
              </p>
              <p className="text-gray-700">
                <strong>CIF:</strong> 12212775
              </p>
            </div>

            {/* Banking Information */}
            <div>
              <h4 className="text-lg font-semibold text-[var(--primary-hover)]">
                {t("informatii_bancare")}
              </h4>
              <ul className="text-gray-700 space-y-2">
                <li>
                  <strong>RON:</strong> RO78BREL0002001934910100
                </li>
                <li>
                  <strong>EUR:</strong> RO94BREL0002001934910200
                </li>
                <li>
                  <strong>USD:</strong> RO13BREL0002001934910300
                </li>
                <li>
                  <strong>{t("banca")}:</strong> LIBRA INTERNET BANK
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
