import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const PaginaPresedinte = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>
          Nicolae Vasilescu Președintele Camerei de Comerț și Industrie
          România-China
        </title>
        <meta
          name="description"
          content="
          Nicolae Vasilescu, președintele Camerei de Comerț și Industrie România-China
        "
        />
        <meta
          name="keywords"
          content="nicolae vasilescu, presedintele camerei de comert si industrie romania-china, romania, china, ccircoffice, ccroch"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="min-h-screen flex flex-col items-center bg-white rounded-lg text-gray-800 px-6 py-4">
        {/* Imaginea */}
        <div className="w-full max-w-md">
          <img
            src="/nicolae_vasilescu.jpg"
            alt="Nicolae Vasilescu"
            className="w-1/2 mx-auto h-auto rounded-lg object-cover"
          />
        </div>

        {/* Conținutul */}
        <div className="w-full max-w-3xl mt-6">
          {/* Titlul */}
          <h1 className="text-3xl font-bold text-gray-900 text-center">
            {t("ing_jur_nicolae_vasilescu")}
          </h1>
          <p className="text-lg text-blue-600 font-semibold text-center">
            {t("presedintele_camerei_de_comert")}
          </p>

          <p className="text-justify mt-4">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("presedinte_text")}
          </p>
          <p className="font-semibold text-lg mt-4">
            {t("ing_jur_nicolae_vasilescu")},
            <br />
            {t("presedintele_camerei_de_comert")}
          </p>
        </div>
      </div>
    </>
  );
};

export default PaginaPresedinte;
