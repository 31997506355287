import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";

const EventsTable = () => {
  const { i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Agenda Evenimentelor CCIRC 2025</title>
        <meta
          name="description"
          content="
          Agenda Evenimentelor CCIRC 2025"
        />
        <meta
          name="keywords"
          content="agenda, agenda ccirc, agenda 2025, romania, china, ccircoffice, ccroch"
        />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <div className="p-3 bg-white rounded-lg">
        <div className="px-5 pt-5">
          <h1 className="text-4xl font-extrabold text-[var(--primary-color)] text-center mb-8">
            Agenda Evenimentelor CCIRC 2025
          </h1>
        </div>
        {i18n.language === "ro" && (
          <div>
            <img
              src="/agenda-images/agenda1.webp"
              alt="Ianuarie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda2.webp"
              alt="Februarie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda3.webp"
              alt="Martie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda4.webp"
              alt="Aprilie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda5.webp"
              alt="Mai"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda6.webp"
              alt="Iunie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda7.webp"
              alt="Iulie"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda8.webp"
              alt="August"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda9.webp"
              alt="Septembrie"
              className="w-full rounded-lg"
            />
          </div>
        )}
        {i18n.language === "en" && (
          <div>
            <img
              src="/agenda-images/agenda1en.webp"
              alt="January"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda2en.webp"
              alt="February"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda3en.webp"
              alt="March"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda4en.webp"
              alt="April"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda5en.webp"
              alt="May"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda6en.webp"
              alt="June"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda7en.webp"
              alt="July"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda8en.webp"
              alt="August"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda9en.webp"
              alt="September"
              className="w-full rounded-lg"
            />
          </div>
        )}
        {i18n.language === "ch" && (
          <div>
            <img
              src="/agenda-images/agenda1en.webp"
              alt="January"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda2en.webp"
              alt="February"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda3en.webp"
              alt="March"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda4en.webp"
              alt="April"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda5en.webp"
              alt="May"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda6en.webp"
              alt="June"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda7en.webp"
              alt="July"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda8en.webp"
              alt="August"
              className="w-full rounded-lg"
            />
            <img
              src="/agenda-images/agenda9en.webp"
              alt="September"
              className="w-full rounded-lg"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default EventsTable;
