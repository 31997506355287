import React, { useState } from "react";
import "daisyui";
import { Link } from "react-router-dom";

export default function AdminPanel() {
  const [isMemberModalOpen, setIsMemberModalOpen] = useState(false);

  const openMemberModal = () => setIsMemberModalOpen(true);
  const closeMemberModal = () => setIsMemberModalOpen(false);

  return (
    <div className="container mx-auto p-6 mt-10">
      <h1 className="text-3xl font-bold mb-8 text-center">Admin Panel</h1>
      <div className="flex justify-center space-x-4">
        <Link className="btn btn-primary" to="/create">
          Crează postare nouă
        </Link>
        <button className="btn btn-secondary" onClick={openMemberModal}>
          Crează cont de membru
        </button>
      </div>

      {/* Modal pentru Crează Cont de Membru */}
      {isMemberModalOpen && (
        <div className="modal modal-open">
          <div className="modal-box bg-[#F1190E]">
            <h3 className="font-bold text-lg text-white mb-4">
              Crează un cont nou pentru membru
            </h3>
            <CreateMemberForm />
            <div className="modal-action">
              <button className="btn" onClick={closeMemberModal}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

function CreateMemberForm() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    if (username.length < 5 || username.length > 64) {
      newErrors.username =
        "Username trebuie să aibă între 5 și 64 de caractere.";
    }

    // Validare password
    if (password.length < 6 || password.length > 64) {
      newErrors.password = "Parola trebuie să aibă între 6 și 64 de caractere.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!validateForm()) {
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username,
            password,
            firstname,
            lastname,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong!");
      }

      setUsername("");
      setPassword("");
      setFirstname("");
      setLastname("");
      setLoading(false);
      setSuccess("Contul a fost creat cu succes!");
    } catch (err) {
      alert(err.message);
      setLoading(false);
    }
  };

  return (
    <div className="space-y-4">
      {success && (
        <p className="text-green-500 font-extrabold text-lg text-center">
          {success}
        </p>
      )}

      {errors.username && (
        <p className="text-white font-extrabold text-lg text-center">
          {errors.username}
        </p>
      )}

      {errors.password && (
        <p className="text-white font-extrabold text-lg text-center">
          {errors.password}
        </p>
      )}

      <form onSubmit={handleCreateAccount}>
        <div className="mb-4">
          <label
            className="block text-gray-100 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Username-ul utilizatorului
          </label>
          <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute left-3 text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
              />
            </svg>
            <input
              type="text"
              id="username"
              placeholder="Introduceți username-ul de conectare"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-100 text-sm font-bold mb-2"
            htmlFor="lastname"
          >
            Numele utilizatorului
          </label>
          <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute left-3 text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>

            <input
              type="text"
              id="lastname"
              placeholder="Introduceți numele utilizatorului"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-100 text-sm font-bold mb-2"
            htmlFor="firstname"
          >
            Prenumele utilizatorului
          </label>
          <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute left-3 text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
              />
            </svg>
            <input
              type="text"
              id="firstname"
              placeholder="Introduceți prenumele utilizatorului"
              value={firstname}
              onChange={(e) => setFirstname(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-gray-100 text-sm font-bold mb-2"
            htmlFor="password"
          >
            Parola
          </label>
          <div className="relative flex items-center text-gray-600 focus-within:text-gray-900">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="size-6 absolute left-3 text-gray-600"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 1 0-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 0 0 2.25-2.25v-6.75a2.25 2.25 0 0 0-2.25-2.25H6.75a2.25 2.25 0 0 0-2.25 2.25v6.75a2.25 2.25 0 0 0 2.25 2.25Z"
              />
            </svg>
            <input
              type="password"
              id="password"
              placeholder="Introduceți parola"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-white hover:bg-gray-100 text-[var(--primary-color)] py-2 px-4 rounded-lg font-medium flex items-center justify-center"
          disabled={loading}
        >
          {loading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-3"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V2.5"
                ></path>
              </svg>
              Loading...
            </>
          ) : (
            "Crează cont"
          )}
        </button>
      </form>
    </div>
  );
}
