import React, { useState } from "react";
import { membriCCIRC } from "../data/membri";
import { useTranslation } from "react-i18next";

export default function MembriCCIRC() {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const itemsPerPage = 20;

  const { t } = useTranslation();

  const filteredMembri = membriCCIRC.filter((membru) =>
    membru.nume.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Paginare
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredMembri.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredMembri.length / itemsPerPage);

  React.useEffect(() => {
    setCurrentPage(1);
  }, [searchTerm]);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center text-gray-900">
        {t("membri_ccirc")}
      </h1>
      <p className="text-center text-gray-600 mb-6">
        {t("lista_membrilor_ccirc")}
      </p>

      <div className="mb-6">
        <input
          type="text"
          placeholder={t("cautare_dupa_nume")}
          className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <p className="text-sm text-gray-500 mt-2">
          {filteredMembri.length} {t("membri_gasiti")}
        </p>
      </div>

      <div className="overflow-x-auto shadow-md rounded-lg">
        <table className="min-w-full bg-white">
          <thead className="bg-blue-600 text-white">
            <tr>
              <th className="py-4 px-6 text-left">Nr.</th>
              <th className="py-4 px-6 text-left">{t("nume_membru")}</th>
              <th className="py-4 px-6 text-left">{t("data_inscrierii")}</th>
              <th className="py-4 px-6 text-left">{t("judet")}</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {currentItems.map((membru) => (
              <tr
                key={membru.nr}
                className="hover:bg-gray-50 transition-colors"
              >
                <td className="py-3 px-6">{membru.nr}</td>
                <td className="py-3 px-6 font-medium">{membru.nume}</td>
                <td className="py-3 px-6">{membru.data}</td>
                <td className="py-3 px-6">{membru.judet}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Paginare îmbunătățită */}
      <div className="flex items-center justify-between mt-6">
        <div className="text-sm text-gray-600">
          {t("afisez_membri")} {indexOfFirstItem + 1} -{" "}
          {Math.min(indexOfLastItem, filteredMembri.length)} {t("din")}{" "}
          {filteredMembri.length}
        </div>

        <div className="flex space-x-2">
          <button
            onClick={() => setCurrentPage(1)}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded bg-white disabled:opacity-50"
          >
            «
          </button>
          <button
            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
            disabled={currentPage === 1}
            className="px-4 py-2 border rounded bg-white disabled:opacity-50"
          >
            ‹
          </button>

          {/* Numerotare pagini */}
          {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
            let pageNum;
            if (totalPages <= 5) {
              pageNum = i + 1;
            } else if (currentPage <= 3) {
              pageNum = i + 1;
            } else if (currentPage >= totalPages - 2) {
              pageNum = totalPages - 4 + i;
            } else {
              pageNum = currentPage - 2 + i;
            }

            return (
              <button
                key={pageNum}
                onClick={() => setCurrentPage(pageNum)}
                className={`px-4 py-2 border rounded ${
                  currentPage === pageNum
                    ? "bg-blue-600 text-white"
                    : "bg-white"
                }`}
              >
                {pageNum}
              </button>
            );
          })}

          <button
            onClick={() =>
              setCurrentPage((prev) => Math.min(prev + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded bg-white disabled:opacity-50"
          >
            ›
          </button>
          <button
            onClick={() => setCurrentPage(totalPages)}
            disabled={currentPage === totalPages}
            className="px-4 py-2 border rounded bg-white disabled:opacity-50"
          >
            »
          </button>
        </div>
      </div>
    </div>
  );
}
