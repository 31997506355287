import React from "react";

export default function BlogPostSkeleton() {
  return (
    <div className="max-w-3xl mx-auto bg-gray-100 shadow rounded-md blog-post overflow-hidden my-5 animate-pulse">
      <div className="w-full h-64 bg-gray-300"></div>
      <div className="p-4 flex flex-col justify-around h-full">
        <div className="h-4 bg-gray-300 rounded w-1/3 self-end mb-2"></div>
        <div className="h-6 bg-gray-300 rounded w-2/3 mb-4"></div>
        <div className="h-4 bg-gray-300 rounded w-full mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-5/6 mb-2"></div>
        <div className="h-4 bg-gray-300 rounded w-3/4 mb-4"></div>
        <div className="h-5 bg-gray-300 rounded w-1/4 self-start"></div>
      </div>
    </div>
  );
}
