export const membriCCIRC = [
  {
    nr: 1,
    nume: "3B CONSTRUCTION PROJECT SRL",
    data: "02.06.2017",
    judet: "IAȘI",
  },
  {
    nr: 2,
    nume: "A & S MED WAREHOUSE SRL",
    data: "02.06.2017",
    judet: "IAȘI",
  },
  {
    nr: 3,
    nume: "ACAPULCO DUPLEX SRL",
    data: "06.03.2020",
    judet: "PRAHOVA",
  },
  {
    nr: 4,
    nume: "ACR TECHNOLOGY SRL",
    data: "03.08.2017",
    judet: "IAȘI",
  },
  {
    nr: 5,
    nume: "ADY MET RECYCLING SRL.",
    data: "31.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 6,
    nume: "AEM TIMISOARA SA",
    data: "16.03.2020",
    judet: "TIMIȘ",
  },
  {
    nr: 7,
    nume: "AFACERI MEDIA GRUP  SRL",
    data: "09.08.2007",
    judet: "BUCUREȘTI",
  },
  {
    nr: 8,
    nume: "AGMUS SA",
    data: "02.10.1997",
    judet: "IAȘI",
  },
  {
    nr: 9,
    nume: "AGRICOMOIL VAST SRL",
    data: "23.09.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 10,
    nume: "AGRIROFARMS SRL",
    data: "23.01.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 11,
    nume: "AGRO - UTIL SRL",
    data: "04.12.2017",
    judet: "TIMIȘ",
  },
  {
    nr: 12,
    nume: "AGRO TM CAMPUS SRL",
    data: "06.11.2017",
    judet: "TIMIȘ",
  },
  {
    nr: 13,
    nume: "AGROINDCOM SA",
    data: "14.01.2005",
    judet: "IAȘI",
  },
  {
    nr: 14,
    nume: "AGROINDUSTRIALA HOREZU",
    data: "04.02.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 15,
    nume: "AGRONORD INVESTIMPEX SRL",
    data: "05.06.2023",
    judet: "NEAMȚ",
  },
  {
    nr: 16,
    nume: "AGROTRANSPORT SA",
    data: "08.03.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 17,
    nume: "AIRAM SMARALD SRL",
    data: "12.04.2021",
    judet: "GIURGIU",
  },
  {
    nr: 18,
    nume: "ALAVAL PROD COM SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 19,
    nume: "AMIPROD WOOD SRL",
    data: "22.04.2024",
    judet: "NEAMȚ",
  },
  {
    nr: 20,
    nume: "ANA VELICI SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 21,
    nume: "ANCA BANATOLACT SRL",
    data: "25.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 22,
    nume: "ANDACO AMBIENEAMȚ SRL",
    data: "30.10.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 23,
    nume: "ANTON JAHNN ASSET MANAGEMENT SRL",
    data: "01.10.2020",
    judet: "BUFTEA",
  },
  {
    nr: 24,
    nume: "ARGOTI GMP SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 25,
    nume: "ARIEL OFFICE SERVICE SRL",
    data: "22.09.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 26,
    nume: "AROMINEAMȚ TRADE  COMPANY SRL",
    data: "09.09.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 27,
    nume: "ARTA POPULARA SOCOM",
    data: "10.06.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 28,
    nume: "ASCENSIS SUNLIGHT SRL",
    data: "22.09.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 29,
    nume: "ASCENT PROCONSTRUCT SRL",
    data: "27.10.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 30,
    nume: "ASIGURAREA POPULARA ROMANA SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 31,
    nume: 'ASOCIATIA CLB. SP. AL PESCARILOR "DUNAREA ALBASTRA',
    data: "30.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 32,
    nume: "ASOCIATIA CULTURALA PETREUSII",
    data: "14.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 33,
    nume: "ASOCIATIA PESCARILOR SPORTIVI CARAS",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 34,
    nume: "ASSA IMPORT EXPORT SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 35,
    nume: "ASSOCIETED BUSINESS ADVISORS",
    data: "10.02.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 36,
    nume: "ATCOM SRL",
    data: "11.08.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 37,
    nume: "ATP EXODUS SRL",
    data: "19.09.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 38,
    nume: "AUTHORITY INEAMȚERNATIONAL GROUP SRL",
    data: "01.03.2012",
    judet: "BUCUREȘTI",
  },
  {
    nr: 39,
    nume: "AUTO BARA & CO SRL",
    data: "08.03.2018",
    judet: "BIHOR",
  },
  {
    nr: 40,
    nume: "AUTO SOFI TRAND SRL-D",
    data: "17.03.2020",
    judet: "TELEORMAN",
  },
  {
    nr: 41,
    nume: "AUTOCHEMICALS SRL",
    data: "25.08.2021",
    judet: "ILFOV",
  },
  {
    nr: 42,
    nume: "AUTODARIS CAR SERVICES SRL",
    data: "12.07.2018",
    judet: "TIMIȘ",
  },
  {
    nr: 43,
    nume: "AUTOMOBILE CRAIOVA SA",
    data: "24.11.1997",
    judet: "DOLJ",
  },
  {
    nr: 44,
    nume: "AVANT GARDE SISTEME SRL",
    data: "01.10.2017",
    judet: "TIMIȘ",
  },
  {
    nr: 45,
    nume: "AVISSO ACCOUTING SERVICES SRL",
    data: "22.10.2018",
    judet: "IAȘI",
  },
  {
    nr: 46,
    nume: "BALLI METAL SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 47,
    nume: "BENDKOPP FASTENERS IMPORT EXPORT SRL",
    data: "28.07.2023",
    judet: "CLUJ",
  },
  {
    nr: 48,
    nume: "BICO INDUSTRIES SRL",
    data: "22.09.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 49,
    nume: "BLUE  PLANET SERCVICES  SRL",
    data: "03.03.2023",
    judet: "BUCUREȘTI",
  },
  {
    nr: 50,
    nume: "BODNAR VASILE SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 51,
    nume: "BRIGHTILFOVY SRL",
    data: "21.11.2019",
    judet: "ILFOV",
  },
  {
    nr: 52,
    nume: "BUSINESS CONCEPT SRL",
    data: "21.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 53,
    nume: "BUSINESS DIVISION GROUP SRL",
    data: "30.10.2017",
    judet: "IAȘI",
  },
  {
    nr: 54,
    nume: "C & D INEAMȚERNATIONAL IMPEX  TEAM SRL",
    data: "19.06.2009",
    judet: "BUCUREȘTI",
  },
  {
    nr: 55,
    nume: "CAB AV FLAVIA TEODOSIU",
    data: "01.10.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 56,
    nume: "CAB AV GHEORGHE FUNIERU",
    data: "01.10.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 57,
    nume: "CABINET INDIVIDUAL MEDICAL DR. REVES CAROL",
    data: "14.09.2018",
    judet: "GALAȚI",
  },
  {
    nr: 58,
    nume: "CAIMROM SA",
    data: "12.06.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 59,
    nume: "CALARASI NEWS SRL",
    data: "24.03.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 60,
    nume: "CALIMĂNEȘTI-CĂCIULATA SA",
    data: "04.09.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 61,
    nume: "CAMUSAT ROM TELECOMUNICATII SRL",
    data: "30.03.2018",
    judet: "ILFOV",
  },
  {
    nr: 62,
    nume: "CANGO MOBILITY SRL",
    data: "10.03.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 63,
    nume: "CAPISIZU & ASOCIATII - SCA",
    data: "12.12.2022",
    judet: "BUCUREȘTI",
  },
  {
    nr: 64,
    nume: "CAPITAL VISION SRL",
    data: "27.04.2023",
    judet: "VRANCEA",
  },
  {
    nr: 65,
    nume: "CARAVELLE SRL",
    data: "20.01.2016",
    judet: "ILFOV",
  },
  {
    nr: 66,
    nume: "CARBOCHIM SA",
    data: "10.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 67,
    nume: "CARGO SRL",
    data: "04.02.2008",
    judet: "ILFOV",
  },
  {
    nr: 68,
    nume: "CARPAT APROMETAL PRODUCTION SRL",
    data: "22.09.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 69,
    nume: "CARPATINA SA",
    data: "06.05.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 70,
    nume: "CASA DE VIS YANG SRL",
    data: "31.08.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 71,
    nume: "CASTELUL LUPILOR SRL",
    data: "15.06.2020",
    judet: "HUNEDOARA",
  },
  {
    nr: 72,
    nume: "CAT CONSTRUCT SRL",
    data: "20.11.2018",
    judet: "IAȘI",
  },
  {
    nr: 73,
    nume: "CB GLOBAL TRADE SRL",
    data: "12.05.2017",
    judet: "ARGEȘ",
  },
  {
    nr: 74,
    nume: "CCIA VRANCEA",
    data: "24.11.1997",
    judet: "VRANCEA",
  },
  {
    nr: 75,
    nume: "CEFMUR SA",
    data: "14.12.2006",
    judet: "MUREȘ",
  },
  {
    nr: 76,
    nume: "CENTRUL DE MEDICINA CHINEZA",
    data: "17.12.2005",
    judet: "CLUJ",
  },
  {
    nr: 77,
    nume: "CERADENT SRL",
    data: "10.07.2017",
    judet: "GALAȚI",
  },
  {
    nr: 78,
    nume: "CHALET CONCEPT SRL",
    data: "19.09.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 79,
    nume: "CHIMSPORT SA",
    data: "28.11.2017",
    judet: "CLUJ",
  },
  {
    nr: 80,
    nume: "CHINA BUSINESS TRAVEL  SRL",
    data: "05.10.2009",
    judet: "BUCUREȘTI",
  },
  {
    nr: 81,
    nume: "CHINA CONNECT SRL",
    data: "01.10.2023",
    judet: "BUCURESTI",
  },
  {
    nr: 82,
    nume: "CHINA STAR & INDUSTRIES IMPEX   SRL",
    data: "07.10.2007",
    judet: "BRAȘOV",
  },
  {
    nr: 83,
    nume: "CHINA TRAVEL SRL",
    data: "01.01.2024",
    judet: "BUCUREȘTI",
  },
  {
    nr: 84,
    nume: "CIVIC SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 85,
    nume: "CIVIL CONSTRUCTIONS SRL",
    data: "30.08.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 86,
    nume: "CLR DENIS MONEAMȚAJ SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 87,
    nume: "CLUB SPORTIV WING CHUN BAIA MARE",
    data: "21.01.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 88,
    nume: "COMAT MURES SA",
    data: "24.11.1997",
    judet: "MUREȘ",
  },
  {
    nr: 89,
    nume: "COMES SA",
    data: "28.11.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 90,
    nume: "COMNSAL COMPANY SRL",
    data: "18.10.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 91,
    nume: "COMPANIA DE LIBRARII BUCURESTI SA",
    data: "15.03.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 92,
    nume: "COMUNA POMEZEU SRL",
    data: "12.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 93,
    nume: "CONCORDIA GRUP SRL",
    data: "12.04.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 94,
    nume: "CONEST SA",
    data: "18.07.2017",
    judet: "IAȘI",
  },
  {
    nr: 95,
    nume: "CONLAN SRL",
    data: "21.03.2025",
    judet: "SIBIU",
  },
  {
    nr: 96,
    nume: "CONSILIUL LOCAL BUJORENI",
    data: "08.04.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 97,
    nume: "CONSTRUCTORUL SARARD SRL",
    data: "30.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 98,
    nume: "CONSULTANȚĂ SI DEZVOLTARE CSD SRL",
    data: "14.02.2005",
    judet: "BUCUREȘTI",
  },
  {
    nr: 99,
    nume: "CONSUMCOOP ANDA CENEI",
    data: "24.02.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 100,
    nume: "CONSUMCOOP ARANCA DUDESTII-VECHI",
    data: "25.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 101,
    nume: "CONSUMCOOP FOIENI",
    data: "25.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 102,
    nume: "CONSUMCOOP PERIAM",
    data: "24.02.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 103,
    nume: "CONSUMCOOP RACOVITA",
    data: "24.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 104,
    nume: "CONSUMCOOP SANNICOLAU MARE",
    data: "25.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 105,
    nume: "CONSUMCOOP UIVAR",
    data: "25.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 106,
    nume: "CONSUMCOOP UNIVERS BILED",
    data: "24.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 107,
    nume: "CONTINENTAL FAST LINE SRL",
    data: "27.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 108,
    nume: "COOPERATIVA DE CONSUM CALATELE",
    data: "09.10.2005",
    judet: "CLUJ",
  },
  {
    nr: 109,
    nume: "COOPERATIVA FEDERAL COOP TIMIS",
    data: "24.01.2012",
    judet: "TIMIȘ",
  },
  {
    nr: 110,
    nume: "COPDAT IMPEX SRL",
    data: "04.04.2018",
    judet: "TELEORMAN",
  },
  {
    nr: 111,
    nume: "CORIALMO IMPEX SRL",
    data: "04.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 112,
    nume: "COSCO - CHINA SHIPPING (ROMANIA) AGENCY SRL",
    data: "01.01.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 113,
    nume: "COZIA FOREST SA",
    data: "11.12.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 114,
    nume: "CPL GALAȚIOBAL BUSINESS SRL",
    data: "06.03.2023",
    judet: "ILFOV",
  },
  {
    nr: 115,
    nume: "CRIOMEC SA",
    data: "10.07.2017",
    judet: "GALAȚI",
  },
  {
    nr: 116,
    nume: "CRISDAR NEW CONSTRUCT SRL",
    data: "15.02.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 117,
    nume: "CRISTAR NEW  CONSTRUCT SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 118,
    nume: "CRONOS CONSULTING SRL",
    data: "15.06.2017",
    judet: "IAȘI",
  },
  {
    nr: 119,
    nume: "CROSI PRODUCT SRL",
    data: "10.08.2016",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 120,
    nume: "DAC INVESTITII SI CONSTRUCTII SRL",
    data: "04.01.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 121,
    nume: "DACIKA DISTRIBUTIE SRL",
    data: "10.04.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 122,
    nume: "DAFICI PROD SRL",
    data: "24.08.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 123,
    nume: "DALMASTER CONSULTING SRL",
    data: "01.03.2012",
    judet: "BUCUREȘTI",
  },
  {
    nr: 124,
    nume: "DATA SYSTEMS SRL",
    data: "11.05.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 125,
    nume: "DEBICANEAMȚ PERFORM SRL",
    data: "01.09.2023",
    judet: "CLUJ",
  },
  {
    nr: 126,
    nume: "DECORA REZIDENEAMȚ SRL",
    data: "12.04.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 127,
    nume: "DEPANERO SRL",
    data: "31.01.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 128,
    nume: "DING COMMUNICATION SRL",
    data: "01.10.2020",
    judet: "DOLJ",
  },
  {
    nr: 129,
    nume: "DIOGENIS INEAMȚERNATIONAL SRL",
    data: "07.02.2019",
    judet: "PRAHOVA",
  },
  {
    nr: 130,
    nume: "DIRECT LINE INOX IMPEX SRL",
    data: "26.03.2021",
    judet: "CLUJ",
  },
  {
    nr: 131,
    nume: "DREAMLIGHT VISUALS SRL-D",
    data: "13.03.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 132,
    nume: "DRIFTER FISHING SRL",
    data: "08.08.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 133,
    nume: "DRUMUL VESNICEI MACIN SRL",
    data: "06.03.2023",
    judet: "TULCEA",
  },
  {
    nr: 134,
    nume: "DYOMEDICA CND SRL",
    data: "30.08.2019",
    judet: "PRAHOVA",
  },
  {
    nr: 135,
    nume: "E CONT SRL",
    data: "10.06.2017",
    judet: "IAȘI",
  },
  {
    nr: 136,
    nume: "E LOGISTIC CORPORATION  SRL",
    data: "26.07.2021",
    judet: "CONSTANȚA",
  },
  {
    nr: 137,
    nume: "ECHINOX COM SRL",
    data: "10.08.2016",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 138,
    nume: "ECO ANEAMȚIC SRL",
    data: "21.01.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 139,
    nume: "ECO LILFOVE STYLE SRL",
    data: "22.12.2021",
    judet: "ILFOV",
  },
  {
    nr: 140,
    nume: "ECO VALAHIA SRL",
    data: "04.01.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 141,
    nume: "ECULDA SRL",
    data: "21.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 142,
    nume: "EDIAL FIELD SRL",
    data: "15.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 143,
    nume: "EDIHUA COMEX SRL",
    data: "01.03.2010",
    judet: "BUCUREȘTI",
  },
  {
    nr: 144,
    nume: "EDWARD BISSE MARKETING STRATEGIES SRL",
    data: "01.10.2020",
    judet: "BUFTEA",
  },
  {
    nr: 145,
    nume: "EKO INTERNATIONAL INVEST SRL",
    data: "10.03.2020",
    judet: "ILFOV",
  },
  {
    nr: 146,
    nume: "ELECTRO EUROSTAR SRL",
    data: "02.08.2016",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 147,
    nume: "ELECTRO EXCEL GREEN CONSULTING SRL",
    data: "04.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 148,
    nume: "ELECTROENEL SERVICES SRL",
    data: "12.04.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 149,
    nume: "ELECTROMARA SRL",
    data: "09.10.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 150,
    nume: "ELECTROMECANICA SA",
    data: "20.03.2005",
    judet: "CLUJ",
  },
  {
    nr: 151,
    nume: "ELENA RUI IMPORT-EXPORT SRL",
    data: "26.03.2010",
    judet: "BUCUREȘTI",
  },
  {
    nr: 152,
    nume: "EMILIANO-VEST SRL",
    data: "25.08.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 153,
    nume: "ENERGOBIT SA",
    data: "26.07.2023",
    judet: "CLUJ",
  },
  {
    nr: 154,
    nume: "ENERGOMONTAJ SA",
    data: "23.05.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 155,
    nume: "EPRUOM SA",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 156,
    nume: "ERASCO PRODIMPEX SRL",
    data: "26.07.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 157,
    nume: "ERO JOBS SRL",
    data: "12.03.2012",
    judet: "HUNEDOARA",
  },
  {
    nr: 158,
    nume: "ESCUGRUP MENAGEMENT SRL",
    data: "06.03.2023",
    judet: "GIURGIU",
  },
  {
    nr: 159,
    nume: "ESPACO INVESTMENT SRL",
    data: "09.04.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 160,
    nume: "EURO TEAM GB SPEDITION SRL",
    data: "06.11.2020",
    judet: "ALBA",
  },
  {
    nr: 161,
    nume: "EURO TEHNIC SRL",
    data: "26.07.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 162,
    nume: "EURO TRANS URSU SRL",
    data: "12.08.2016",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 163,
    nume: "EUROASIA IMMIGRATION SERVICES SRL",
    data: "12.03.2025",
    judet: "ILFOV",
  },
  {
    nr: 164,
    nume: "EUROCOOPER SRL",
    data: "06.03.2020",
    judet: "TIMIȘ",
  },
  {
    nr: 165,
    nume: "EUROPA SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 166,
    nume: "EUROPLUS PRIN SRL",
    data: "30.10.2018",
    judet: "GALAȚI",
  },
  {
    nr: 167,
    nume: "EXLIBRIS SA",
    data: "05.06.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 168,
    nume: "EXPLO MINING  COAL SRL",
    data: "01.03.2017",
    judet: "TIMIȘ",
  },
  {
    nr: 169,
    nume: "EXPLOMING SRL",
    data: "15.02.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 170,
    nume: "EXPRESS FORWARDING SRL",
    data: "01.07.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 171,
    nume: "F & J INTERNATIONAL ROMANIA SA",
    data: "26.03.2010",
    judet: "BUZĂU",
  },
  {
    nr: 172,
    nume: "FARMEC SA",
    data: "10.03.2005",
    judet: "CLUJ",
  },
  {
    nr: 173,
    nume: "FAVIL SA",
    data: "08.04.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 174,
    nume: "FAXMEDIA CONSULTING SRL",
    data: "11.02.2018",
    judet: "PRAHOVA",
  },
  {
    nr: 175,
    nume: "FEDER ALCOOP SA",
    data: "13.02.2005",
    judet: "CLUJ",
  },
  {
    nr: 176,
    nume: "FEDERALCOOP SA",
    data: "06.01.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 177,
    nume: "FIBROCIM SRL",
    data: "18.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 178,
    nume: "FINANCO MANAGEMENEAMȚ SRL",
    data: "16.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 179,
    nume: "FLORA ARNO SRL",
    data: "10.07.2020",
    judet: "ARGEȘ",
  },
  {
    nr: 180,
    nume: "FRP VISAN SRL",
    data: "30.10.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 181,
    nume: "FTF INTERNATIONAL SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 182,
    nume: "GAMA SHIPING &TRADING SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 183,
    nume: "GAMES SRL",
    data: "19.12.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 184,
    nume: "GBA PLASTIK SRL",
    data: "30.09.2019",
    judet: "ARGEȘ",
  },
  {
    nr: 185,
    nume: "GENERAL MEEL ELECTRIC SRL",
    data: "27.10.2021",
    judet: "PRAHOVA",
  },
  {
    nr: 186,
    nume: "GENERAL SERVICE RAPID SRL",
    data: "25.03.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 187,
    nume: "GENERAL TURBO SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 188,
    nume: "GEOASCENT SRL",
    data: "31.08.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 189,
    nume: "GHEBO INTERNATIONAL SRL",
    data: "20.01.2016",
    judet: "BUCUREȘTI",
  },
  {
    nr: 190,
    nume: "GIC NOSAG METAL SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 191,
    nume: "GLASS PROTECTION SRL",
    data: "03.09.2018",
    judet: "IAȘI",
  },
  {
    nr: 192,
    nume: "GOODWILL CONSULTING SRL",
    data: "22.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 193,
    nume: "GRAMPET SA",
    data: "09.07.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 194,
    nume: "GRAND BAZAR  STECK HOOSE CONCEPT SA",
    data: "16.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 195,
    nume: "GRANDE GLORIA SRL",
    data: "10.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 196,
    nume: "GRIMET CONSTRUCT SRL",
    data: "23.01.2018",
    judet: "TIMIȘ",
  },
  {
    nr: 197,
    nume: "H&H GROUP COMPANY 2005 SRL",
    data: "04.08.2005",
    judet: "ILFOV",
  },
  {
    nr: 198,
    nume: "HERVIL SA",
    data: "10.02.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 199,
    nume: "HOLDE INEAMȚERNATIONAL SRL",
    data: "20.06.2018",
    judet: "ILFOV",
  },
  {
    nr: 200,
    nume: "HOT PAPRIKA SRL",
    data: "18.07.2018",
    judet: "SĂLAJ",
  },
  {
    nr: 201,
    nume: "HUA LONG JI YE IMPEX SRL",
    data: "31.08.2001",
    judet: "BUCUREȘTI",
  },
  {
    nr: 202,
    nume: "HUAWEI TECHNOLOGIES ROMANIA SRL",
    data: "26.07.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 203,
    nume: "HYPERICUM IMPEX SRL",
    data: "22.10.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 204,
    nume: "ICSIM COMPANY SRL",
    data: "10.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 205,
    nume: "IDEAL TECHNOLGY SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 206,
    nume: "IMAGE SERVICE PRODCOM SRL",
    data: "22.10.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 207,
    nume: "IMPACT DEVELOPER & CONTRACTOR SA",
    data: "20.02.2019",
    judet: "ILFOV",
  },
  {
    nr: 208,
    nume: "IMPREST SA",
    data: "14.09.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 209,
    nume: "INA BUILDING CONCEPT SRL",
    data: "23.01.2018",
    judet: "TIMIȘ",
  },
  {
    nr: 210,
    nume: "INDECO SOFT SRL",
    data: "21.01.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 211,
    nume: "INDUSTRIAL PARTS TRADING SRL",
    data: "21.08.2018",
    judet: "PRAHOVA",
  },
  {
    nr: 212,
    nume: "INEAMȚEL GLOBALIS SRL",
    data: "09.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 213,
    nume: "INEDIT CLEAN TOTAL SRL",
    data: "23.04.2015",
    judet: "ILFOV",
  },
  {
    nr: 214,
    nume: "INRERARMS IMPEX SRL",
    data: "06.06.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 215,
    nume: "INTEGRATED SOLUTIONS PROVIDER SRL",
    data: "02.03.2023",
    judet: "BUCUREȘTI",
  },
  {
    nr: 216,
    nume: "INTELEGO CAPITAL SRL",
    data: "18.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 217,
    nume: "INTERSOFT START SOLUTION SRL",
    data: "10.07.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 218,
    nume: "INVEST PROSPEED ESM SRL",
    data: "21.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 219,
    nume: "INVESTMENT ADH INT SRL",
    data: "06.09.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 220,
    nume: "IVAS SRL",
    data: "18.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 221,
    nume: "JUNIOR MED SRL",
    data: "12.08.2018",
    judet: "IAȘI",
  },
  {
    nr: 222,
    nume: "KARAFTMEN ALL STORE SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 223,
    nume: "KEPLER SOFTWARE SRL",
    data: "15.02.2005",
    judet: "BUCUREȘTI",
  },
  {
    nr: 224,
    nume: "KIT XENON TUNING SRL",
    data: "13.01.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 225,
    nume: "KLG EUROPE LOGISTIC SRL",
    data: "09.03.2020",
    judet: "GIURGIU",
  },
  {
    nr: 226,
    nume: "KMG INTERNATIONAL N.V. SRL",
    data: "07.11.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 227,
    nume: "KPMG ROMANIA SRL",
    data: "28.01.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 228,
    nume: "KR EASTERN EUROPE SRL",
    data: "16.05.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 229,
    nume: "KRAFTSMAN ALL STORE SRL",
    data: "17.02.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 230,
    nume: "KREEISTAD CONSTRUCTION SRL",
    data: "17.09.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 231,
    nume: "LATINA PLASTICARAȘ SEVERIN SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 232,
    nume: "LAVINIA UNGAR DESIGN SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 233,
    nume: "LEMNKING INDUSTRY  COM SRL",
    data: "26.03.2010",
    judet: "BUZĂU",
  },
  {
    nr: 234,
    nume: "LIBRA INEAMȚERNET BANK SA",
    data: "29.03.2013",
    judet: "BUCUREȘTI",
  },
  {
    nr: 235,
    nume: "LOC IND SA",
    data: "14.11.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 236,
    nume: "LTD BEARINGS GROUP SRL",
    data: "06.06.2018",
    judet: "ILFOV",
  },
  {
    nr: 237,
    nume: "LUNA B & R SRL",
    data: "01.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 238,
    nume: "LUXURY DECO STAR SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 239,
    nume: "MACROMEX SRL",
    data: "03.02.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 240,
    nume: "MADI SUPER TOYS SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 241,
    nume: "MAIREDER AT SRL",
    data: "08.08.2016",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 242,
    nume: "MAR SPRINT SOLUTIONS SRL",
    data: "06.03.2020",
    judet: "PRAHOVA",
  },
  {
    nr: 243,
    nume: "MARCEL PROD TAPITERIE SRL",
    data: "22.05.2019",
    judet: "GALAȚI",
  },
  {
    nr: 244,
    nume: "MARELECTRIC SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 245,
    nume: "MAR-INA PRODPREST SRL",
    data: "23.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 246,
    nume: "MARINA PROPERTIES CONSTRUCT SRL",
    data: "01.09.2019",
    judet: "CLUJ",
  },
  {
    nr: 247,
    nume: "MARSH BROKER DE ASIGURARE-REASIGURARE SRL",
    data: "15.01.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 248,
    nume: "MARUDRO GENERAL SERVICES SRL",
    data: "24.08.2016",
    judet: "DÂMBOVIȚA",
  },
  {
    nr: 249,
    nume: "MAZARS CONSULTING SRL",
    data: "11.12.2009",
    judet: "BUCUREȘTI",
  },
  {
    nr: 250,
    nume: "MCL PROCONCEPT SRL",
    data: "14.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 251,
    nume: "MEDIASTING SRL",
    data: "08.09.2022",
    judet: "SIBIU",
  },
  {
    nr: 252,
    nume: "MEDICHINA RCH SRL",
    data: "20.01.2016",
    judet: "ILFOV",
  },
  {
    nr: 253,
    nume: "MELIO FARM SRL",
    data: "12.09.2023",
    judet: "CLUJ",
  },
  {
    nr: 254,
    nume: "MENZER & BACHAMNN- NOERR SRL",
    data: "29.03.2011",
    judet: "BUCUREȘTI",
  },
  {
    nr: 255,
    nume: "MESTERUL NOSTRU SRL",
    data: "12.04.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 256,
    nume: "MG MEDICAL ECOLINE SRL",
    data: "20.03.2020",
    judet: "ILFOV",
  },
  {
    nr: 257,
    nume: "MG SELF PLAST SRL",
    data: "20.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 258,
    nume: "MIGDALIN SRL",
    data: "20.11.2018",
    judet: "IAȘI",
  },
  {
    nr: 259,
    nume: "MINET SA",
    data: "05.12.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 260,
    nume: "MINISO SRL",
    data: "05.09.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 261,
    nume: "MIRA EXPORT SRL",
    data: "21.06.2017",
    judet: "IAȘI",
  },
  {
    nr: 262,
    nume: "MIRDATOD PROD SRL",
    data: "18.05.2020",
    judet: "MUREȘ",
  },
  {
    nr: 263,
    nume: "M-MARKETING MOBILE SRL",
    data: "03.04.2020",
    judet: "ILFOV",
  },
  {
    nr: 264,
    nume: "MOARA GLIGA SRL",
    data: "30.10.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 265,
    nume: "MOBITEX HOREZU87 SA",
    data: "13.02.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 266,
    nume: "MOISI SERV COM SRL",
    data: "05.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 267,
    nume: "MUNTENIA INVEST SRL",
    data: "18.12.2017",
    judet: "GIURGIU",
  },
  {
    nr: 268,
    nume: "MUSAT & ASOCIATII SCA",
    data: "13.05.2009",
    judet: "BUCUREȘTI",
  },
  {
    nr: 269,
    nume: "MUSAT SI ASOCIATII",
    data: "14.11.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 270,
    nume: "MYSTIC EURIMPEX SRL",
    data: "30.08.2019",
    judet: "BUCUREȘTI",
  },
  {
    nr: 271,
    nume: "NANOTECH DEFENCE SRL",
    data: "23.05.2023",
    judet: "ILFOV",
  },
  {
    nr: 272,
    nume: "NATCERT SRL",
    data: "21.12.2020",
    judet: "MUREȘ",
  },
  {
    nr: 273,
    nume: "NAVANIS CONSTRUCT SRL",
    data: "05.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 274,
    nume: "NEW ECO-STER SRL",
    data: "01.02.2018",
    judet: "MEHEDINȚI",
  },
  {
    nr: 275,
    nume: "NEW KOPEL ROMANIA SRL",
    data: "28.09.2009",
    judet: "ILFOV",
  },
  {
    nr: 276,
    nume: "NICOLAE PICIORUS SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 277,
    nume: "NICORI PROFESSIONAL STUDIO COMPANY SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 278,
    nume: "NOVA TRADING EUROPE SRL",
    data: "24.11.2022",
    judet: "BUCUREȘTI",
  },
  {
    nr: 279,
    nume: "NOVATEX SRL",
    data: "04.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 280,
    nume: "NVB BUILDING & CONSULTING SRL",
    data: "11.02.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 281,
    nume: "OBERHAUSERINVEST SRL",
    data: "19.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 282,
    nume: "OCTAVIA CHIM SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 283,
    nume: "ODAS GLOBAL CONSULTING SRL",
    data: "21.01.2020",
    judet: "CLUJ",
  },
  {
    nr: 284,
    nume: "OMNIASIG SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 285,
    nume: "ONE COTROCENI",
    data: "10.02.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 286,
    nume: "OPTIMAL TRAVEL SRL",
    data: "19.07.2018",
    judet: "IAȘI",
  },
  {
    nr: 287,
    nume: "ORIENT GLOBAL SRL",
    data: "06.06.2006",
    judet: "BUCUREȘTI",
  },
  {
    nr: 288,
    nume: "PACO ACTIV CONSTRUCT SRL",
    data: "27.02.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 289,
    nume: "PALIMPEX SRL",
    data: "26.10.2017",
    judet: "TELEORMAN",
  },
  {
    nr: 290,
    nume: "PALTINEI COM SRL",
    data: "25.10.2005",
    judet: "CLUJ",
  },
  {
    nr: 291,
    nume: "PANDA TRAVEL AGENCY SRL",
    data: "15.02.2008",
    judet: "BUCUREȘTI",
  },
  {
    nr: 292,
    nume: "PANIPAT 3 SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 293,
    nume: "PARVU PERSU  SCA",
    data: "19.07.2023",
    judet: "CLUJ",
  },
  {
    nr: 294,
    nume: "PATI CRIDVAL SRL",
    data: "21.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 295,
    nume: "PAUL ALBU GROUP SRL",
    data: "28.08.2018",
    judet: "TELEORMAN",
  },
  {
    nr: 296,
    nume: "PAVAJ GRUP SRL",
    data: "09.01.2018",
    judet: "HUNEDOARA",
  },
  {
    nr: 297,
    nume: "PEFIN & PRO SRL",
    data: "20.09.2018",
    judet: "TELEORMAN",
  },
  {
    nr: 298,
    nume: "PENTAROM SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 299,
    nume: "PIATA DE GROS SA",
    data: "15.01.2005",
    judet: "CLUJ",
  },
  {
    nr: 300,
    nume: "PKG SE GRUP SRL",
    data: "19.02.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 301,
    nume: "PLASTOMET SA",
    data: "01.08.2018",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 302,
    nume: "POP & PARTNERS SRL",
    data: "02.02.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 303,
    nume: "POP FRANCE SRL",
    data: "18.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 304,
    nume: "PRESTATORUL SRL",
    data: "10.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 305,
    nume: "PRIMERA MED TECHNOLOGY SRL",
    data: "13.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 306,
    nume: "PRINCESS SECRET SRL",
    data: "10.01.2018",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 307,
    nume: "PRIVAT SILVIU ZETEA SRL",
    data: "15.11.2019",
    judet: "SATU MARE",
  },
  {
    nr: 308,
    nume: "PROFAL INDUSTRY SRL",
    data: "24.05.2018",
    judet: "BUCUREȘTI",
  },
  {
    nr: 309,
    nume: "PROFI PENTRU SANATATE SRL",
    data: "20.05.2020",
    judet: "BUZĂU",
  },
  {
    nr: 310,
    nume: "PROLAC FIN  CALOUR SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 311,
    nume: "PROLISOK SRL",
    data: "06.09.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 312,
    nume: "PROMOS WEST SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 313,
    nume: "PROVEN SYSTEM SRL",
    data: "02.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 314,
    nume: "PRUTUL SA",
    data: "10.09.2018",
    judet: "GALAȚI",
  },
  {
    nr: 315,
    nume: "PSI CONS EXPERT OFFICE SRL",
    data: "01.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 316,
    nume: "PUILFOVRAGED SRL",
    data: "22.10.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 317,
    nume: "QSET ENERGY SRL",
    data: "14.10.2008",
    judet: "BUCUREȘTI",
  },
  {
    nr: 318,
    nume: "RADOR A&E SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 319,
    nume: "RAI INTER LOGISTIC SRL",
    data: "22.10.2018",
    judet: "PRAHOVA",
  },
  {
    nr: 320,
    nume: "RECOND COM. IMPEX SRL",
    data: "20.09.2018",
    judet: "TELEORMAN",
  },
  {
    nr: 321,
    nume: "RECYCLING PLASTIC TNS SRL",
    data: "20.09.2018",
    judet: "TELEORMAN",
  },
  {
    nr: 322,
    nume: "REGIONAL CONSULTING SRL",
    data: "06.09.2016",
    judet: "SUCEAVA",
  },
  {
    nr: 323,
    nume: "RENAISANCE STAR SRL",
    data: "10.08.2018",
    judet: "GALAȚI",
  },
  {
    nr: 324,
    nume: "RESPICOM SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 325,
    nume: "RETA SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 326,
    nume: "RETESAN INGINERIE SI ARHICTURA SRL",
    data: "16.08.2023",
    judet: "CLUJ",
  },
  {
    nr: 327,
    nume: "REVIVA INOVATION SRL",
    data: "21.08.2023",
    judet: "MUREȘ",
  },
  {
    nr: 328,
    nume: "REVOLUTION PLUS SRL",
    data: "12.03.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 329,
    nume: "RICKY IMPEX SRL",
    data: "13.01.2010",
    judet: "ILFOV",
  },
  {
    nr: 330,
    nume: "RMN GLOBAL SRL",
    data: "14.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 331,
    nume: "ROCON SRL",
    data: "11.09.2021",
    judet: "ILFOV",
  },
  {
    nr: 332,
    nume: "ROGLAS IND SRL",
    data: "21.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 333,
    nume: "ROINVEST CONSTRUCT SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 334,
    nume: "ROINVEST SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 335,
    nume: "ROMANIA TEHMAN SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 336,
    nume: "ROMENERGO SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 337,
    nume: "ROSTRADA SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 338,
    nume: "SAAS FOR FUTURE SRL",
    data: "30.08.2019",
    judet: "GALAȚI",
  },
  {
    nr: 339,
    nume: "SACO SA",
    data: "04.06.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 340,
    nume: "ȘAGAU SHIPPING SERVICES SRL",
    data: "17.12.2024",
    judet: "CONSTANTA",
  },
  {
    nr: 341,
    nume: "SAMPLAST DAN PRODUCTION SRL",
    data: "09.03.2023",
    judet: "BIHOR",
  },
  {
    nr: 342,
    nume: "SAN SALVA HUMANA SRL",
    data: "22.10.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 343,
    nume: "SANALIS PROD SRL",
    data: "08.05.2020",
    judet: "BIHOR",
  },
  {
    nr: 344,
    nume: "SARIMED LTD SRL",
    data: "18.06.2018",
    judet: "IAȘI",
  },
  {
    nr: 345,
    nume: "SAVINI DUE SRL",
    data: "11.07.2024",
    judet: "ALBA",
  },
  {
    nr: 346,
    nume: "SAVODASINFO SRL",
    data: "06.03.2020",
    judet: "PRAHOVA",
  },
  {
    nr: 347,
    nume: "SCHENKER LOGISTIC ROMANIA SRL",
    data: "09.08.2021",
    judet: "BUCUREȘTI",
  },
  {
    nr: 348,
    nume: "SCPA PAUNESCU RAZVAN & ASOCIATII",
    data: "04.01.2017",
    judet: "BUCUREȘTI",
  },
  {
    nr: 349,
    nume: "SCPP VALCEA",
    data: "26.05.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 350,
    nume: "SEM ECOPELETI REȘIȚA SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 351,
    nume: "SER TRANS SRL",
    data: "21.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 352,
    nume: "SERVICII EDILITARE COMUNITATE MIOVENI",
    data: "28.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 353,
    nume: "SFARA TOURS SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 354,
    nume: "SHANDONG NINGJIAN CONSTRUCTION GROUP  SRL",
    data: "26.03.2010",
    judet: "BUCUREȘTI",
  },
  {
    nr: 355,
    nume: "SHART 2 TECH IMPEX SRL",
    data: "20.04.2023",
    judet: "BIHOR",
  },
  {
    nr: 356,
    nume: "SICOM INVESTITII CONSTRUCTII SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 357,
    nume: "SINO EURO DEVELOPMENT COM   SRL",
    data: "22.01.2010",
    judet: "BUZĂU",
  },
  {
    nr: 358,
    nume: "SIPATI PLUS SRL",
    data: "09.03.2020",
    judet: "MARAMUREȘ",
  },
  {
    nr: 359,
    nume: "SIRO GLOBAL  SOLUTIONS SRL",
    data: "20.01.2016",
    judet: "ILFOV",
  },
  {
    nr: 360,
    nume: "SKY OPTICENTER SRL-D",
    data: "15.02.2018",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 361,
    nume: "SMART NEW TENS SRL",
    data: "18.07.2018",
    judet: "TIMIȘ",
  },
  {
    nr: 362,
    nume: "SMART VENDORS SRL",
    data: "12.03.2025",
    judet: "BUCURESTI",
  },
  {
    nr: 363,
    nume: "SNQ. CO SRL",
    data: "07.09.2017",
    judet: "IAȘI",
  },
  {
    nr: 364,
    nume: "SOC. MEDICO BALNEARA",
    data: "03.09.2005",
    judet: "CLUJ",
  },
  {
    nr: 365,
    nume: "SOCOM OLTUL SRL",
    data: "18.10.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 366,
    nume: "SOCOM PROGRESUL SRL",
    data: "06.08.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 367,
    nume: "SOCOM SARGUINTA SRL",
    data: "09.07.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 368,
    nume: "SODEXO PASS ROMANIA SRL",
    data: "06.04.2012",
    judet: "BUCUREȘTI",
  },
  {
    nr: 369,
    nume: "SOF PRODUCTION STANIJA SRL",
    data: "10.01.2018",
    judet: "HUNEDOARA",
  },
  {
    nr: 370,
    nume: "SORELA AGROIND COM 97 SRL",
    data: "20.01.2016",
    judet: "ILFOV",
  },
  {
    nr: 371,
    nume: "SORFEDO SRL",
    data: "11.05.2008",
    judet: "TELEORMAN",
  },
  {
    nr: 372,
    nume: "SPACS MARGHITA",
    data: "14.03.2020",
    judet: "BIHOR",
  },
  {
    nr: 373,
    nume: "SPECCHIASOL ROMANIA SRL",
    data: "20.11.2018",
    judet: "IAȘI",
  },
  {
    nr: 374,
    nume: "SPEDIAL SRL",
    data: "04.09.2019",
    judet: "BIHOR",
  },
  {
    nr: 375,
    nume: "SPICUL ETAP SA",
    data: "20.08.2019",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 376,
    nume: "SPRINTEN INFOMAR SRL",
    data: "22.10.2018",
    judet: "PRAHOVA",
  },
  {
    nr: 377,
    nume: "SREM BUCURESTI SRL-D",
    data: "21.02.2020",
    judet: "BUCUREȘTI",
  },
  {
    nr: 378,
    nume: "STAER INTERNATIONAL SA",
    data: "18.10.2018",
    judet: "GALAȚI",
  },
  {
    nr: 379,
    nume: "STIMA ARDELEANA SRL",
    data: "24.11.1997",
    judet: "SIBIU",
  },
  {
    nr: 380,
    nume: "SUMA CONSULTING SRL",
    data: "17.05.2017",
    judet: "CĂLĂRAȘI",
  },
  {
    nr: 381,
    nume: "SUPER TOT INVEST CONSTRUCT SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 382,
    nume: "T & S IMPEX SRL",
    data: "21.06.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 383,
    nume: "TAFF CATSYM SRL",
    data: "06.03.2023",
    judet: "ARAD",
  },
  {
    nr: 384,
    nume: "TB DEZVOLTARE SERV SRL",
    data: "05.04.2018",
    judet: "TIMIȘ",
  },
  {
    nr: 385,
    nume: "TECHNO HOUSE SRL",
    data: "14.05.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 386,
    nume: "TEHNOFRIG SA",
    data: "11.10.2005",
    judet: "CLUJ",
  },
  {
    nr: 387,
    nume: "TEHNOTERM INDUSTRIAL SRL",
    data: "08.05.2013",
    judet: "BUCUREȘTI",
  },
  {
    nr: 388,
    nume: "TELE MEDIA PRODUCTION SRL",
    data: "12.08.2018",
    judet: "IAȘI",
  },
  {
    nr: 389,
    nume: "TE-ROX PROD SRL",
    data: "03.02.2020",
    judet: "IAȘI",
  },
  {
    nr: 390,
    nume: "TEUTON NORD SRL",
    data: "15.11.2019",
    judet: "MARAMUREȘ",
  },
  {
    nr: 391,
    nume: "THE BLACK SWAN PROJECT SRL",
    data: "10.03.2023",
    judet: "CONSTANȚA",
  },
  {
    nr: 392,
    nume: "TIPOALEX SA",
    data: "26.10.2017",
    judet: "TELEORMAN",
  },
  {
    nr: 393,
    nume: "TIR 2000 SRL",
    data: "14.07.2008",
    judet: "BUCUREȘTI",
  },
  {
    nr: 394,
    nume: "TONSON EXPERT SRL",
    data: "07.03.2019",
    judet: "BIHOR",
  },
  {
    nr: 395,
    nume: "TOPAZ COM SRL",
    data: "17.03.2020",
    judet: "TELEORMAN",
  },
  {
    nr: 396,
    nume: "TOTAL AUTOSERV SRL",
    data: "10.01.2019",
    judet: "GORJ",
  },
  {
    nr: 397,
    nume: "TOTAL TOOLS SRL",
    data: "22.11.2021",
    judet: "HUNEDOARA",
  },
  {
    nr: 398,
    nume: "TQM SERVICES SRL",
    data: "17.05.2017",
    judet: "CĂLARAȘI",
  },
  {
    nr: 399,
    nume: "TRAMOS LOGISTIC SRL",
    data: "23.10.2019",
    judet: "BIHOR",
  },
  {
    nr: 400,
    nume: "TRANSAVIA SA",
    data: "24.09.2020",
    judet: "ALBA",
  },
  {
    nr: 401,
    nume: "TRANSILVANIA MADERAS SRL",
    data: "02.09.2021",
    judet: "MARAMUREȘ",
  },
  {
    nr: 402,
    nume: "TRIPOL SISTEM",
    data: "17.12.2024",
    judet: "BUCUREȘTI",
  },
  {
    nr: 403,
    nume: "TRUCK BSB AUTO SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 404,
    nume: "TRUSTED ADVISER – BUSINESS & BENEFITS SRL",
    data: "01.06.2017",
    judet: "GORJ",
  },
  {
    nr: 405,
    nume: "TUCA ZBARCEA & ASOCIATII",
    data: "08.05.2012",
    judet: "BUCUREȘTI",
  },
  {
    nr: 406,
    nume: "TUDEXIM SRL",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 407,
    nume: "TURIST HOTEL SA",
    data: "16.08.2005",
    judet: "CLUJ",
  },
  {
    nr: 408,
    nume: "TWOMAR KONTACT SERVICES SRL",
    data: "16.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 409,
    nume: "TYPO DAS SOLUTIONS SRL",
    data: "26.10.2017",
    judet: "PRAHOVA",
  },
  {
    nr: 410,
    nume: "UJCC “FEDERALCOOP”Alexandria",
    data: "24.11.1997",
    judet: "TELEORMAN",
  },
  {
    nr: 411,
    nume: "UNITED ELECTRIC INDUSTRY  SRL",
    data: "22.01.2010",
    judet: "BUZĂU",
  },
  {
    nr: 412,
    nume: "UNIVERS SA",
    data: "09.02.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 413,
    nume: "URBIOLED SRL",
    data: "18.09.2022",
    judet: "IAȘI",
  },
  {
    nr: 414,
    nume: "UZINA MECANICA BABENI SA",
    data: "07.11.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 415,
    nume: "UZINA MECANICA DRAGASANI SA",
    data: "10.07.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 416,
    nume: "UZINA SODICE GOVORA",
    data: "16.09.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 417,
    nume: "UZINEXPORTIMPORT SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 418,
    nume: "UZINSINDER SA",
    data: "24.11.1997",
    judet: "BUCUREȘTI",
  },
  {
    nr: 419,
    nume: "VÂLCEANA SA",
    data: "09.11.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 420,
    nume: "VALEAN PRIGOANA SNC",
    data: "06.08.2005",
    judet: "CLUJ",
  },
  {
    nr: 421,
    nume: "VALMETEX SRL",
    data: "04.06.2005",
    judet: "VÂLCEA",
  },
  {
    nr: 422,
    nume: "VAST BOITA PLAI SA",
    data: "15.10.2020",
    judet: "BIHOR",
  },
  {
    nr: 423,
    nume: "VERLA SRL",
    data: "06.07.2023",
    judet: "CLUJ",
  },
  {
    nr: 424,
    nume: "VERTICAL CONSTRUCT SRL",
    data: "06.03.2023",
    judet: "ARGEȘ",
  },
  {
    nr: 425,
    nume: "VES SA",
    data: "28.11.2017",
    judet: "MUREȘ",
  },
  {
    nr: 426,
    nume: "VETRO SOLUTIONS SRL",
    data: "20.11.2018",
    judet: "GALAȚI",
  },
  {
    nr: 427,
    nume: "VICTOR & DANY SRL",
    data: "08.11.2022",
    judet: "BUCUREȘTI",
  },
  {
    nr: 428,
    nume: "VILFOVIL OPTIM SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 429,
    nume: "VLADEASA SA",
    data: "15.12.2005",
    judet: "CLUJ",
  },
  {
    nr: 430,
    nume: "VP HOLDING MANAGEMENT SISTEM SRL",
    data: "28.10.2011",
    judet: "BUCUREȘTI",
  },
  {
    nr: 431,
    nume: "WELTPIXEL SRL",
    data: "09.09.2020",
    judet: "CLUJ",
  },
  {
    nr: 432,
    nume: "WESTERN OUTDOOR SRL",
    data: "26.03.2010",
    judet: "BUCUREȘTI",
  },
  {
    nr: 433,
    nume: "WINGS BUSINESS WORLD SRL",
    data: "24.05.2019",
    judet: "ARAD",
  },
  {
    nr: 434,
    nume: "WIRE FENCE  FACTORY SRL",
    data: "27.03.2023",
    judet: "TULCEA",
  },
  {
    nr: 435,
    nume: "WORLD D'ALEXIA SRL",
    data: "12.07.2018",
    judet: "GALAȚI",
  },
  {
    nr: 436,
    nume: "YDA TOUR SRL",
    data: "26.10.2017",
    judet: "NEAMȚ",
  },
  {
    nr: 437,
    nume: "YSL LOSSYS SRL",
    data: "01.09.2017",
    judet: "CARAȘ SEVERIN",
  },
  {
    nr: 438,
    nume: "YUAN DONG",
    data: "01.05.2005",
    judet: "CLUJ",
  },
  {
    nr: 439,
    nume: "YUTANG SRL",
    data: "14.07.2008",
    judet: "ILFOV",
  },
  {
    nr: 440,
    nume: "ZENYTH PHARMACEUTICALS SRL",
    data: "18.01.2018",
    judet: "NEAMȚ",
  },
  {
    nr: 441,
    nume: "ZETO AUTOMATIC SRL",
    data: "21.01.2021",
    judet: "BUCUREȘTI",
  },
  {
    nr: 442,
    nume: "ZTE ROMANIA SRL",
    data: "04.01.2017",
    judet: "BUCUREȘTI",
  },
];
