import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="container justify-center mt-2">
      {/* Navbar for large screens */}
      <div className="hidden lg:flex min-[1140px]:flex max-[1140px]:flex-col bg-gradient-to-r from-[var(--primary-hover)] via-[var(--primary-hover)] to-[var(--primary-color)] shadow-lg rounded-lg justify-between text-white">
        <div className="navbar-center">
          <ul
            className={`menu menu-horizontal max-[1400px]:text-[14px] max-[1140px]:mx-auto font-bold px-3 ${
              i18n.language === "en" ? "text-[15px]" : "text-[16px]"
            }`}
          >
            {/* Add your existing menu items here */}
            <li>
              <Link to="/">{t("acasa")}</Link>
            </li>
            <li>
              <Link to="/prezentare">{t("prezentare")}</Link>
            </li>
            <li>
              <Link to="/echipa">{t("echipa")}</Link>
            </li>
            <li>
              <Link to="/agenda">{t("agenda")}</Link>
            </li>
            <li>
              <Link to="/parteneriate">{t("parteneriate")}</Link>
            </li>
            <li>
              <details>
                <summary>{t("facilitati")}</summary>
                <ul className="p-2 w-max bg-gradient-to-r z-50 from-[var(--primary-color)] to-[var(--primary-hover)]">
                  <li>
                    <Link to="/prezentare-facilitati">{t("facilitati_1")}</Link>
                  </li>
                  <li>
                    <Link to="/facilitati-actionar-romanesc">
                      {t("facilitati_2")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/facilitati-actionar-chinezesc">
                      {t("facilitati_3")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/facilitati-companii-chineze">
                      {t("facilitati_4")}
                    </Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>{t("servicii")}</summary>
                <ul className="p-2 w-max bg-gradient-to-r z-50 from-[var(--primary-color)] to-[var(--primary-hover)]">
                  <li>
                    <Link to="/catalog-servicii-afaceri-companii">
                      {t("catalog_servicii_afaceri")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/catalog-servicii-logistica">
                      {t("catalog_servicii_logistica")}
                    </Link>
                  </li>
                </ul>
              </details>
            </li>
            {/* Dropdowns */}
            <li>
              <details>
                <summary>{t("filiale")}</summary>
                <ul className="p-2 w-max bg-gradient-to-r z-50 from-[var(--primary-color)] to-[var(--primary-hover)]">
                  <li>
                    <Link to="/filiala-romania">{t("filiale_ro")}</Link>
                  </li>
                  <li>
                    <Link to="/reprezentante-china">
                      {t("reprezentante_ch")}
                    </Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>{t("membri")}</summary>
                <ul className="p-2 w-max bg-gradient-to-r z-50 from-[var(--primary-color)] to-[var(--primary-hover)]">
                  <li>
                    <Link to="/membri">{t("membri_ccirc")}</Link>
                  </li>
                  <li>
                    <Link to="/membri-de-onoare">{t("membri_onoare")}</Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <details>
                <summary>{t("aderare")}</summary>
                <ul className="p-2 w-max bg-gradient-to-r z-50 from-[var(--primary-color)] to-[var(--primary-hover)]">
                  <li>
                    <Link to="/cerere-aderare">{t("cerere_de_aderare")}</Link>
                  </li>
                  <li>
                    <Link to="/contract-adeziune-membru">
                      {t("contract_de_adeziune")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/fisa-companiei">
                      {t("fisa_companiei_domenii")}
                    </Link>
                  </li>
                  <li>
                    <Link to="/fisa-companii">{t("fisa_companii")}</Link>
                  </li>
                  <li>
                    <Link to="/mandat-de-reprezentare">
                      {t("mandat_de_reprezentare")}
                    </Link>
                  </li>
                </ul>
              </details>
            </li>
            <li>
              <Link to="/contact">{t("contact")}</Link>
            </li>
            <li className="hidden max-[1140px]:block">
              <Link
                to="/sustine-ccirc"
                className={`px-3 font-bold rounded-lg bg-[var(--primary-hover)] transition duration-300 hover:scale-105 ${
                  i18n.language === "en" ? "text-[14px]" : "text-[16px]"
                }`}
              >
                {t("sustine_ccirc")}!
              </Link>
            </li>
          </ul>
        </div>
        <Link
          to="/sustine-ccirc"
          className={`px-3 font-bold mr-2 rounded-lg my-2 bg-[var(--primary-hover)] transition duration-300 hidden min-[1141px]:flex hover:scale-105 items-center text-center justify-center
  ${i18n.language === "en" ? "text-[14px]" : "text-[16px]"}`}
        >
          {t("sustine_ccirc")}!
        </Link>
      </div>

      {/* Mobile Menu */}
      <div className="lg:hidden flex justify-between items-center bg-gradient-to-r from-[var(--primary-hover)] to-[var(--primary-color)] p-4">
        <div className="text-white text-lg font-bold">{t("meniu")}</div>
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          {/* Hamburger Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="w-6 h-6"
          >
            {isOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16m-7 6h7"
              />
            )}
          </svg>
        </button>
      </div>

      {isOpen && (
        <div className="lg:hidden bg-gradient-to-r from-[var(--primary-hover)] to-[var(--primary-color)] text-white text-center">
          <ul className="menu menu-vertical font-bold space-y-4 py-4">
            {/* Mobile Menu Items */}
            <li>
              <Link onClick={toggleMenu} to="/">
                {t("acasa")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/prezentare">
                {t("prezentare")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/echipa">
                {t("echipa")}
              </Link>
            </li>
            <li>
              <Link onClick={toggleMenu} to="/agenda">
                {t("agenda")}
              </Link>
            </li>
            <li>
              <Link to="/parteneriate" onClick={toggleMenu}>
                {t("parteneriate")}
              </Link>
            </li>
            <li>
              <Link to="/prezentare-facilitati" onClick={toggleMenu}>
                {t("facilitati_1")}
              </Link>
            </li>
            <li>
              <Link to="/facilitati-actionar-romanesc" onClick={toggleMenu}>
                {t("facilitati_2")}
              </Link>
            </li>
            <li>
              <Link to="/facilitati-actionar-chinezesc" onClick={toggleMenu}>
                {t("facilitati_3")}
              </Link>
            </li>
            <li>
              <Link to="/facilitati-companii-chineze" onClick={toggleMenu}>
                {t("facilitati_4")}
              </Link>
            </li>
            <li>
              <Link
                to="/catalog-servicii-afaceri-companii"
                onClick={toggleMenu}
              >
                {t("catalog_servicii_afaceri")}
              </Link>
            </li>
            <li>
              <Link to="/catalog-servicii-logistica" onClick={toggleMenu}>
                {t("catalog_servicii_logistica")}
              </Link>
            </li>
            <li>
              <Link to="/filiala-romania" onClick={toggleMenu}>
                {t("filiale_ro")}
              </Link>
            </li>
            <li>
              <Link to="/reprezentante-china" onClick={toggleMenu}>
                {t("reprezentante_ch")}
              </Link>
            </li>
            <li>
              <Link to="/membri" onClick={toggleMenu}>
                {t("membri_ccirc")}
              </Link>
            </li>
            <li>
              <Link to="/membri-de-onoare" onClick={toggleMenu}>
                {t("membri_onoare")}
              </Link>
            </li>
            <li>
              <Link to="/cerere-aderare" onClick={toggleMenu}>
                {t("cerere_de_aderare")}
              </Link>
            </li>
            <li>
              <Link to="/contract-adeziune-membru" onClick={toggleMenu}>
                {t("contract_de_adeziune")}
              </Link>
            </li>
            <li>
              <Link to="/fisa-companiei" onClick={toggleMenu}>
                {t("fisa_companiei_domenii")}
              </Link>
            </li>
            <li>
              <Link to="/fisa-companii" onClick={toggleMenu}>
                {t("fisa_companii")}
              </Link>
            </li>
            <li>
              <Link to="/mandat-de-reprezentare" onClick={toggleMenu}>
                {t("mandat_de_reprezentare")}
              </Link>
            </li>
            <li>
              <Link to="/contact" onClick={toggleMenu}>
                {t("contact")}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}
