import React from "react";
import AllBlogPosts from "../components/AllBlogPosts";
import NewProfileCard from "../components/NewProfileCard";
import { Helmet } from "react-helmet-async";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>CCIRC - Pagina Principală</title>
        <meta
          name="description"
          content="CCIRC s-a constituit cu scopul de a contribui activ și determinant la promovarea și dezvoltarea relațiilor economice și comerciale ale României cu R.P. Chineză."
        />
        <meta
          name="keywords"
          content="CCIRC, comerț România China, camera de comert si industrie, romania, china, ccircoffice, ccroch"
        />
        <meta
          property="og:title"
          content="Camera de Comerț și Industrie România-China"
        />
        <meta
          property="og:description"
          content="CCIRC s-a constituit cu scopul de a contribui activ și determinant la promovarea și dezvoltarea relațiilor economice și comerciale ale României cu R.P. Chineză."
        />
        <meta property="og:image" content="https://ccroch.ro/hero.webp" />
        <meta property="og:url" content="https://ccroch.ro/" />
        <meta name="robots" content="index, follow" />
      </Helmet>
      <NewProfileCard />
      <AllBlogPosts />
    </div>
  );
}
