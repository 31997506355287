import React, { useEffect, useState } from "react";

// Skeleton pentru WeatherCard
const WeatherCardSkeleton = () => {
  return (
    <div className="relative animate-pulse shadow-md border border-gray-200 rounded-lg px-4 py-2 bg-white w-full">
      <div className="h-7 bg-gray-300 rounded w-1/3 mb-2"></div>
      <div className="h-3 bg-gray-300 rounded w-8/12 mb-1"></div>
      <div className="h-4 bg-gray-300 rounded w-2/5"></div>
      <div className="absolute top-1 right-1 w-9 h-9 bg-gray-300 rounded-xl"></div>
    </div>
  );
};

// Componenta principală skeleton pentru WeatherApp
const WeatherAppSkeleton = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      <WeatherCardSkeleton />
      <WeatherCardSkeleton />
      <WeatherCardSkeleton />
      <WeatherCardSkeleton />
    </div>
  );
};

// Componentă care afișează vremea și ora pentru un anumit oraș
const WeatherCard = ({ city, weather, timeZone }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const date = new Date();
      const timeString = new Intl.DateTimeFormat("en-GB", {
        hour: "2-digit",
        minute: "2-digit",
        timeZone: timeZone,
      }).format(date);
      setCurrentTime(timeString);
    };

    updateTime();
    const intervalId = setInterval(updateTime, 60000);

    return () => clearInterval(intervalId);
  }, [timeZone]);

  const getLocalWeatherIcon = (iconCode) => {
    try {
      return require(`../images/weather/${iconCode}.svg`);
    } catch (error) {
      return require("../images/weather/01d.svg");
    }
  };

  return (
    <div className="shadow-md border border-gray-200 rounded-lg px-4 py-2 relative bg-white w-full">
      {weather && weather.main ? (
        <>
          <div className="flex flex-col">
            <p className="text-xl font-semibold text-black mb-1">
              {Math.round(weather.main.temp)}°C
            </p>
            <p className="text-xs text-gray-600 italic">
              {city}, {weather.sys.country}
            </p>
            <p className="text-sm font-semibold text-gray-800">{currentTime}</p>
          </div>
          <img
            src={getLocalWeatherIcon(weather.weather[0].icon)}
            alt="Weather icon"
            className="w-9 h-9 absolute top-1 right-1"
          />
        </>
      ) : (
        <p className="text-lg text-black">No data available</p>
      )}
    </div>
  );
};

// Componenta principală care face un apel către backend și afișează cardurile meteo
const WeatherApp = () => {
  const [weatherData, setWeatherData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/weather`
        );
        const data = await response.json();
        setWeatherData(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching weather:", error);
      }
    };

    fetchWeatherData();
  }, []);

  if (loading) {
    return <WeatherAppSkeleton />;
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
      {weatherData.length > 0 && (
        <>
          <WeatherCard
            city="Bucharest"
            weather={weatherData.find((data) => data.name === "Bucharest")}
            timeZone="Europe/Bucharest"
          />
          <WeatherCard
            city="Beijing"
            weather={weatherData.find((data) => data.name === "Beijing")}
            timeZone="Asia/Shanghai"
          />
          <WeatherCard
            city="Shanghai"
            weather={weatherData.find((data) => data.name === "Shanghai")}
            timeZone="Asia/Shanghai"
          />
          <WeatherCard
            city="Hong Kong"
            weather={weatherData.find((data) => data.name === "Hong Kong")}
            timeZone="Asia/Hong_Kong"
          />
        </>
      )}
    </div>
  );
};

export default WeatherApp;
